import { apiURL } from "../../usecases/api";
export class ApiIssAnswer {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getIssAnswers() {
    const response = await this.httpClient.request({
      url: `${apiURL}/issanswer?isSummary=true`,
      method: "GET",
    });
    return response;
  }

  async getIssAnswer(id) {
    const response = await this.httpClient.request({
      url: `${apiURL}/issanswer/${id}`,
      method: "GET",
    });
    return response;
  }

  async createIssAnswer(data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/IssAnswer`,
      method: "POST",
      data,
    });
    return response;
  }

  async updateIssAnswer(data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/IssAnswer`,
      method: "PUT",
      data,
    });

    return response;
  }

  async deleteIssAnswer(id) {
    const response = await this.httpClient.request({
      url: `${apiURL}/IssAnswer/${id}`,
      method: "DELETE",
    });
    return response;
  }

  async deleteIssAnswerItem(id) {
    const response = await this.httpClient.request({
      url: `${apiURL}/IssAnswer/item/${id}`,
      method: "DELETE",
    });
    return response;
  }

  async getGroups() {
    const response = await this.httpClient.request({
      url: `${apiURL}/groups`,
      method: "GET",
    });
    return response;
  }
}
