import React from "react";
import { Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { getDateToShowFilter } from "../../../Utils/utilsDate";

function ActiveFilters({ filter, handleChangeFilter }) {
  const isStartDateInvalid = !filter?.start || filter?.start == "Invalid Date";
  const isEndDateInvalid = !filter?.end || filter?.end == "Invalid Date";

  return (
    <Box style={{ display: "flex", marginTop: 3, gap: 5 }}>
      {!isStartDateInvalid && (
        <Box style={styles.FilterContainer}>
          <Box>
            <em style={{ marginRight: 3, fontWeight: 700 }}>Data Inicial:</em>
            {getDateToShowFilter(filter?.start)}
          </Box>
          <Close
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleChangeFilter({ start: null });
            }}
          />
        </Box>
      )}
      {!isEndDateInvalid && (
        <Box style={styles.FilterContainer}>
          <Box>
            <em style={{ marginRight: 3, fontWeight: 700 }}>Data Final:</em>
            {getDateToShowFilter(filter?.end)}
          </Box>
          <Close
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleChangeFilter({ year: "" });
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default ActiveFilters;

const styles = {
  FilterContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#E6E6E6",
    padding: "2px 8px",
    borderRadius: 20,
    justifyContent: "space-between",
    gap: 3,
    fontSize: 12,
    color: "black",
  },
};
