import fakeStates from "./fake-states.json";
import { apiURL } from "../../usecases/api";
export class ApiStateUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getStates() {
    let response = { data: fakeStates };
    try {
      response = await this.httpClient.request({
        url: `${apiURL}/state`,
        method: "GET",
      });
    } catch (err) {
      console.log(err);
    }
    return response.data;
  }
}
