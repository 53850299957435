import { apiURL } from "../../usecases/api";
export class ApiBlogCommentUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAllComments() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/comment`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  async getCommentsByBlogPostId(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/comment/comments-post/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  async getCommentById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/comment/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  async addNewComment(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/comment`,
        method: "POST",
        data: data,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  async submitCommentResponse(data) {
    try {
      const response = await this.httpClient.request({
        url: `/comment`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async updateCommentResponse(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/comment`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async deleteComment(id) {
    try {
      await this.httpClient.request({
        url: `${apiURL}/comment/${id}`,
        method: "DELETE",
      });
      return;
    } catch (error) {
      console.log(error);
    }
  }
}
