import React, { useState } from "react";
import { Box, Button } from "@mui/material";

export const SearchForm = ({ onSearch, searchButtonBg, placeholder }) => {
  const [text, setText] = useState("");
  const handleSearch = () => onSearch(text);
  const bgColor = searchButtonBg || "#2699DA";
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        height: "50px",
        borderRadius: "6px",
      }}
    >
      <input
        onKeyDown={(event) => event.key === "Enter" && handleSearch()}
        placeholder={placeholder || "Pesquisar publicação"}
        onChange={(event) => setText(event.target.value)}
        style={styles.input}
      />
      <Button
        onClick={() => handleSearch()}
        sx={{
          padding: "0px 16px",
          width: "30%",
          borderRadius: "0 6px 6px 0",
          color: "#fff",
          fontSize: 16,
          backgroundColor: bgColor,
          "&:hover": {
            background: bgColor,
            filter: "brightness(0.95)",
          },
        }}
      >
        BUSCAR
      </Button>
    </Box>
  );
};

const styles = {
  input: {
    width: "70%",
    borderRadius: "6px 0 0 6px",
    padding: "0px 14px",
    color: "#414141",
    fontSize: "1rem",
    fontFamily: "Montserrat",
    fontWeight: 500,
    border: "none",
    outline: "none",
    height: "50px",
  },
};
