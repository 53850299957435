import React from "react";
import { makeCnaesUseCases } from "~/main/factories/use-cases/cnaes";
import { makeServiceUseCases } from "~/main/factories/use-cases/service";
import CNAE from "~/presentation/views/Admin/Cnae/CNAE";

export const makeCnaeList = (params) => {
  return (
    <CNAE {...params} cnaesUseCases={makeCnaesUseCases()} serviceUseCases={makeServiceUseCases()} />
  );
};
