import { apiURL } from "../../usecases/api";
export class ApiCnaes {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async listCnaes() {
    const response = await this.httpClient.request({
      url: `${apiURL}/cnae`,
      method: "GET",
    });
    return response;
  }

  async getCnaeById(id) {
    const response = await this.httpClient.request({
      url: `${apiURL}/cnae/${id}`,
      method: "GET",
    });
    return response;
  }

  async createCnae(data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/cnae/`,
      method: "POST",
      data,
    });
    return response;
  }

  async updateCnae(data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/cnae`,
      method: "PUT",
      data,
    });
    return response;
  }

  async deleteCnae(id) {
    const response = await this.httpClient.request({
      url: `${apiURL}/cnae/${id}`,
      method: "DELETE",
    });
    return response;
  }
}
