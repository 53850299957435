import React, { useContext, useEffect, useState } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Stack,
  Box,
  Checkbox,
  Divider,
  Typography as Text,
} from "@mui/material";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { ArrowDownward, ArrowUpward, KeyboardArrowRight } from "@mui/icons-material";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { formatDateToBrazilian } from "../../Utils/utilsDate";
import { formatNumberToRealString } from "~/presentation/utils/formatCurrency";
import SearchInput from "../../components/Search/SearchInput";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import { tableStyles } from "../../components/Styles/TableStyles";
import ModalContainer from "../../components/ModalContainer/ModalContainer";
import { EventDetailsModal } from "../components/EventSend/EventDetailsModal";

function SentTablePayments({
  certificateData,
  sendFilter,
  refreshData,
  paymentsData,
  eventsDataSend,
  isEventClosed,
}) {
  const apiReinf = new ApiReinfUseCases(makeHttpClient());
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  const [eventsData, setEventsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  // CONSULT MODAL
  const [openConsultDetails, setOpenConsultDetails] = useState(false);
  const [consultDetails, setConsultDetails] = useState([]);

  console.log("dados R4010", eventsData);

  useEffect(() => {
    setEventsData(eventsDataSend);
    handleSelectAllClick({ target: { checked: false } });
  }, [eventsDataSend]);

  //ABERTURA E FECHO DE CADA FICHA (COLLAPSE)
  const [openCollapse, setOpenCollapse] = useState({});
  //Open/Close Collapse
  const handleOpenCollapse = (index, isOpen) => {
    setOpenCollapse((prev) => ({
      ...prev,
      [index]: !isOpen,
    }));
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    // Função para filtrar os dados com base na busca
    const filteredData = eventsData?.filter((item) =>
      item.someField?.toLowerCase().includes(value?.toLowerCase())
    );
    setEventsData(filteredData);
  };

  const dataAbledToCheck = eventsData?.filter((item) => item.receiptNumber);
  const numSelected = selectedRows?.length;
  const rowCount = dataAbledToCheck?.length;

  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleSort = (field) => {
    if (field) {
      const isAsc = orderBy === field && orderDirection === "asc";
      setOrderDirection(isAsc ? "desc" : "asc");
      setOrderBy(field);
      const sortedData = [...eventsData].sort((a, b) => {
        if (a[field] < b[field]) {
          return orderDirection === "asc" ? -1 : 1;
        }
        if (a[field] > b[field]) {
          return orderDirection === "asc" ? 1 : -1;
        }
        return 0;
      });
      setEventsData(sortedData);
    }
  };
  const anyStatus1 = eventsData?.filter((item) => item.codeStatus === 1);

  const handleProtocolConsult = async () => {
    const promises = anyStatus1?.map(async (event) => {
      const params = {
        DigitalCertId: sendFilter.certificate,
        ProtocolNumber: event.protocol,
        IsProduction: false,
      };
      const result = await apiReinf.getReinfConsult(params);
      return result;
    });

    try {
      openBackdrop(true, "Consultando protocolos");
      const response = await Promise.all(promises);
      if (response.length > 0) {
        setConsultDetails(response);
        setOpenConsultDetails(true);
      }
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data || error?.response,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedRows(dataAbledToCheck);
    } else {
      setSelectedRows([]);
    }
  };

  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, item]);
    } else {
      setSelectedRows((prev) =>
        prev.filter((selectedItem) => selectedItem.protocol !== item.protocol)
      );
    }
  };

  const isSelected = (item) => selectedRows.some((i) => i.protocol === item.protocol);

  const handleSubmit = () => {
    // Ação para o envio dos dados selecionados
  };

  const getColumns = () => {
    return [
      { field: "protocol", headerName: "Protocolo" },
      { field: "receiptNumber", headerName: "Nº Recibo" },
      { field: "sendingDate", headerName: "Data do envio" },
      { field: "userSent", headerName: "Email do usuário" },
      { headerName: "Evento", sortable: false },
    ];
  };

  const headStyle = { ...tableStyles.headCell, padding: "12px" };

  //PAGINAÇÃO
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calcular os índices de início e fim com base na página e nas linhas por página
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData = eventsData?.length > 0 && eventsData.slice(startIndex, endIndex);

  return (
    <>
      <Box sx={{ paddingInline: 3 }}>
        <Box sx={styles.buttonsContainer}>
          <Box sx={styles.buttonsContainer.buttons}>
            {anyStatus1.length > 0 && (
              <RButton sx={styles.Button} onClick={handleProtocolConsult}>
                Calcular Situação
              </RButton>
            )}
          </Box>
          <SearchInput value={searchTerm} onChange={handleSearch} />
        </Box>
        {/* <Box sx={{ paddingTop: 2, display: "flex", justifyContent: "right" }}>
          <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
            <Text sx={styles.text}>
              Totalizador: <span>{formatNumberToRealString(totalAmountBC)}</span>
            </Text>
          </Stack>
        </Box> */}
      </Box>
      <Box sx={{ ...tableStyles.container, margin: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ ...headStyle, padding: 0 }}>
                <Checkbox
                  color="default"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              {getColumns().map((col, i) => (
                <TableCell
                  sx={{
                    ...headStyle,
                    cursor: col.sortable === false ? "auto" : "pointer",
                  }}
                  key={i}
                  onClick={() => {
                    if (col.sortable === false) return; //precisa ser === false
                    handleSort(col.field);
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {col.headerName}
                    {orderBy === col.field ? (
                      orderDirection === "asc" ? (
                        <ArrowUpward fontSize="small" />
                      ) : (
                        <ArrowDownward fontSize="small" />
                      )
                    ) : null}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {eventsData?.length > 0 ? (
              eventsData?.map((item, index) => {
                const isOpen = openCollapse[index] || false;
                const isRowSelected = isSelected(item);
                const hasReceiptNumber = item?.receiptNumber;
                return (
                  <React.Fragment key={index}>
                    <TableRow sx={{ cursor: "pointer" }}>
                      <TableCell sx={{ ...tableStyles.cell, padding: 0 }}>
                        <Checkbox
                          color="default"
                          onChange={(event) => handleCheckboxChange(event, item)}
                          checked={isRowSelected}
                          disabled={!hasReceiptNumber}
                        />
                      </TableCell>
                      <TableCell sx={tableStyles.cell}>{item.protocol}</TableCell>
                      <TableCell sx={tableStyles.cell}>{item.receiptNumber}</TableCell>
                      <TableCell sx={tableStyles.cell}>
                        {formatDateToBrazilian(item.sendingDate)}
                      </TableCell>
                      <TableCell sx={tableStyles.cell}>{item.userSent}</TableCell>
                      <TableCell sx={tableStyles.cell}>{`R-${item?.reinfEvent.slice(
                        1,
                        5
                      )}`}</TableCell>
                      {/* <TableCell sx={tableStyles.cell}>
                        {formatNumberToRealString(protocolTotals[item.protocol]?.amountBC || 0)}
                      </TableCell> */}
                      {/* <TableCell sx={tableStyles.cell}>
                        {renderStatus(item.codeStatus, item.status)}
                      </TableCell> */}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ ...tableStyles.cellCollapse, padding: "2px " }}
                        colSpan="100&"
                      >
                        {isOpen && (
                          <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            <p>teste</p>
                            {/* <SentTableDocs
                              protocol={item.protocol}
                              filter={filter}
                              validNfseData={validNfseData}
                              status={item.codeStatus}
                              certificateData={certificateData}
                              receiptNumber={item.receiptNumber}
                              refreshData={refreshData}
                              isEventClosed={isEventClosed}
                              automatedData={eventsData[index]?.nfseData || null}
                              selecetedRowsData={setSelectedRows}
                              setEventsData={setEventsData}
                            /> */}
                          </Collapse>
                        )}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })
            ) : (
              <TableRow>
                <TableCell sx={tableStyles.cellError} colSpan="100%">
                  <p style={{ textAlign: "center" }}>Não existe eventos no período escolhido.</p>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {eventsData?.length > 9 && (
          <Stack spacing={1} sx={tableStyles.pagination}>
            <TablePagination
              component="div"
              count={eventsData?.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Informações por página:"
              rowsPerPageOptions={[10, 15, 20]}
            />
          </Stack>
        )}
      </Box>
      <Box sx={{ paddingRight: 3, display: "flex", justifyContent: "right" }}>
        <RButton
          sx={{ width: "200px" }}
          onClick={handleSubmit}
          disabled={selectedRows?.length === 0}
        >
          Enviar
        </RButton>
      </Box>
      {/* <ModalContainer open={openEventSendModal} onClose={handleClose}>
        <EventSend
          data={selectedRows}
          filter={{
            ...filter,
            event:
              selectedEvent === "r2010" || selectedEvent === "r4020"
                ? `automated-${selectedEvent}`
                : selectedEvent,
          }}
          certificateData={certificateData}
          closeModal={handleClose}
          refreshData={refreshData}
        />
      </ModalContainer> */}
      <ModalContainer open={openConsultDetails} onClose={() => setOpenConsultDetails(false)}>
        <EventDetailsModal
          onClose={() => setOpenConsultDetails(false)}
          eventDetails={consultDetails}
        />
      </ModalContainer>
    </>
  );
}

export default SentTablePayments;
const styles = {
  Button: {
    width: "min(300px, 100%)",
    borderRadius: "8px",
    boxShadow: 0,
    textTransform: "uppercase",
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 600,
    color: "#404040",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 1,
    "@media(min-width: 900px)": {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    buttons: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: 1,
      "@media(min-width: 600px)": {
        flexDirection: "row",
      },
      "@media(min-width: 900px)": {
        justifyContent: "start",
      },
    },
  },
};
