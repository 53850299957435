import { apiURL } from "../../usecases/api";

export class ApiAnswer {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAnswersGT() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/gt`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async listAnswers({ itemContent = "" }) {
    let queryParams = "isSummary=true";

    if (itemContent) {
      queryParams += `&itemContent=${itemContent}`;
    }

    const response = await this.httpClient.request({
      url: `${apiURL}/answer?${queryParams}`,
      method: "GET",
    });

    return response;
  }

  async listAnswersSummary(filters) {
    let queryParams = "";

    if (filters) {
      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          queryParams += `${key}=${filters[key]}&`;
        }
      });

      if (queryParams.endsWith("&")) {
        queryParams = queryParams.slice(0, -1);
      }
    }

    const response = await this.httpClient.request({
      url: `${apiURL}/answer/AnswerSummary?${queryParams}`,
      method: "GET",
    });

    return response;
  }

  async getById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/${id}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async createAnswer(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer`,
        method: "POST",
        data,
      });

      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async update(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async deleteAnswer(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/${id}`,
        method: "DELETE",
      });

      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}
