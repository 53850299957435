import * as Yup from "yup";

function isObjectEmpty(obj) {
    return Object.keys(obj).length != 0;
}

export default Yup.object({
    title: Yup.string().required("Campo Obrigatório!"),
    text: Yup.string().required("Campo Obrigatório!"),
    gtAnswerItemType: Yup.object().test('gtAnswerItemType', 'Selecione um tipo de resposta', value => isObjectEmpty(value)).required("Campo obrigatório!"),
});
