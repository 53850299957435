import {apiURL} from "~/data/usecases/api";

export class ApiStatsUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getStatsSearch(companyId, userId, DateStart, DateEnd) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/stats/Search?companyId=${companyId}&userId=${userId}&DateStart=${DateStart}&DateEnd=${DateEnd}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getStatsSearchSummary(companyId) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/stats/Search-summary?companyId=${companyId}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getStatsContentAccess(companyId, isDetail= false) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/stats/Content-access?companyId=${companyId}&isDetail=${isDetail}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
}