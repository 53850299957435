import { floatToReais, formatPercentageReport } from "~/presentation/utils/reais-parser";

export const reportMapper = (data, result) => {
  const report = {
    title: 'IRRF',
    tax: 1,
    retentionValue: result,
    items: [
      {
        descriptionItem: "Alíquota da retenção",
        value: `${formatPercentageReport(data.retentionAliquot.value*100)}%`
      },
      {
        descriptionItem: "Valor da retenção do IR",
        value: floatToReais(result)
      }
    ]
  }
  return report;
}
