import { formatDateToBrazilian } from "../Utils/utilsDate";

export function useTableFilters() {
  const docPeriodFilter = (data, order, start, end) => {
    const result = data.filter(
      (item) => item[order] > start.toJSON() && item[order] < end.toJSON()
    );
    return result;
  };

  const docSituationFilter = (data, value) => {
    let result = [...data];
    //nenhum evento
    if (value === "never") {
      result = result.filter((item) => !item["isR2010"] && !item["isR4020"]);
    }
    //R2010 não tem notas de produto NF-e
    if (value === "isR2010") {
      result = data.filter((item) => item[value]);
    }
    if (value === "isR4020") {
      result = data.filter((item) => item[value]);
    }
    return result;
  };

  const docSetOrder = (data, field) => {
    const result = data?.sort((a, b) => {
      const dateA = new Date(a[field]);
      const dateB = new Date(b[field]);
      return dateB - dateA; // Para ordem decrescente
    });
    return result;
  };

  const docTypeFilter = (data, field, value) => {
    return data.filter((item) => item[field] === value);
  };

  //ncessário no handleSearch
  const normalizeText = (text) => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9\s]/g, "")
      .toUpperCase();
  };

  //Podemos passar as keys que queremos procurar num keys = ["key1", "key2", ...]
  //caso key=null pesuisa em todas as keys da data e procura lá
  const handleSearchTable = (value, data, setData, keys = null) => {
    //normaliza o value enviado no input pelo usuário
    const normalizedValue = normalizeText(value);

    const filtered = data.filter((item) => {
      //keys ou todas as keys da data
      const searchKeys = keys || Object.keys(item);

      for (let key of searchKeys) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          let fieldValue = item[key];

          // Se for uma data, formata para o padrão brasileiro
          if (key.includes("Date")) {
            fieldValue = formatDateToBrazilian(fieldValue);
          }

          // Normaliza o valor do campo
          const normalizedFieldValue = normalizeText(String(fieldValue));

          // Verifica se o valor de pesquisa está presente no campo normalizado
          if (normalizedFieldValue.includes(normalizedValue)) {
            return true;
          }
        }
      }
      return false;
    });

    //adiciona no setData as notas onde campos foram true
    setData(filtered);
  };

  return {
    docPeriodFilter,
    docSituationFilter,
    docSetOrder,
    docTypeFilter,
    handleSearchTable,
  };
}
