import { floatToReais, formatPercentageReport } from "~/presentation/utils/reais-parser";

export const reportMapper = (data, patronalResult, inssRetentionResult) => {
  const report = {
    title: 'INSS',
    tax: 0,
    retentionValue: inssRetentionResult,
    items: [
      {
        descriptionItem: "Alíquota da retenção",
        value: `${formatPercentageReport(data.aliquotRetention.value * 100)}%`
      },
      {
        descriptionItem: "Alíquota da contribuição patronal",
        value: `${formatPercentageReport(data.aliquotPatronalContribution * 100)}%`
      },
      {
        descriptionItem: "Valor da remuneração de outra fonte pagadora",
        value: floatToReais(data.otherPaymentFontValue)
      },
      {
        descriptionItem: "Valor da contribuição patronal",
        value: floatToReais(patronalResult)
      },
      {
        descriptionItem: "Valor da retenção do INSS",
        value: floatToReais(inssRetentionResult)
      }
    ]
  }
  return report;
}
