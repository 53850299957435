import { apiURL } from "../../usecases/api";

export class ApiBlogCategoryUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getBlogCategories() {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/category`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      console.log("Missing endpoint: ", err);
    }
  }

  async getPostsByCategory(categoryId) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/category/${categoryId}/posts`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      console.log("Missing endpoint: ", err);
    }
  }

  async deleteBlogCategory(id) {
    const response = await this.httpClient.request({
      url: `${apiURL}/blog/category/${id}`,
      method: "DELETE",
    });
    return response.data;
  }

  async createBlogCategory(data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/blog/category`,
      method: "POST",
      data,
    });
    return response.data;
  }

  async updateBlogCategory(data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/blog/category/`,
      method: "PUT",
      data,
    });
    return response.data;
  }

  async getBlogCategoryById(id) {
    // let response = {data: blogCategories.find((b) => b.id == id)};
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/category/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getBlogSeries() {
    // let response = {data: blogCategories.filter((b) => b.isSerie === true)};
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/CategorySerie`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
}
