import { floatToReais } from "~/presentation/utils/reais-parser";

export const reportMapper = (data, result) => {
  const report = {
    title: 'INSS',
    tax: 0,
    retentionValue: 0,
    items: [
      {
        descriptionItem: "Valor da Contribuição Patronal Previdenciária (CPP)",
        value: floatToReais(result)
      }
    ]
  }
  return report;
}
