import React from "react";
import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";
import { useTokenCheck } from "~/presentation/hooks/useTokenCheck";

function CountDownToken({ color }) {
  const { timeLeft } = useTokenCheck();
  const value = (timeLeft * 100) / 60; //obter o valor certo da percentagem

  const getColor = () => {
    let current = "#fff";
    if (timeLeft <= 5) current = "#FE7F1E";
    else if (color) current = color;
    return current;
  };

  const getMessage = () => {
    let message = "";
    if (timeLeft > 0) message = `A sua sessão expira em ${timeLeft}min.`;
    if (timeLeft === 0) message = "A sua sessão expirou, por favor atualize a página.";
    return message;
  };

  const currentColor = getColor();
  const message = getMessage();

  return (
    <Box sx={styles.container}>
      <CircularProgress
        variant="determinate"
        sx={styles.underCircularProgress}
        thickness={2.6}
        value={100}
      />
      <CircularProgress
        thickness={2.6}
        variant="determinate"
        value={value}
        sx={{ color: currentColor }}
      />
      <Box sx={{ ...styles.textContainer, color: currentColor }}>
        <Tooltip title={message}>
          <Box>
            <Typography sx={{ ...styles.text, fontSize: 14, lineHeight: "14px" }}>
              {timeLeft}
            </Typography>
            <Typography sx={{ ...styles.text, fontSize: 8, lineHeight: "11px" }}>MIN</Typography>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default CountDownToken;

const styles = {
  container: {
    position: "relative",
    display: "inline-flex",
    margin: "0px 10px",
  },
  underCircularProgress: {
    color: "rgb(183, 183, 183, 0.3)",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
  },
  textContainer: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  text: {
    margin: 0,
    padding: 0,
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
};
