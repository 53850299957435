import { apiURL } from "../../usecases/api";
export class ApiItemAnswer {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAll() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/item-answer`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async getById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/item-answer/${id}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async create(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/item-answer`,
        method: "POST",
        data,
      });

      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async update(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/item-answer`,
        method: "PUT",
        data,
      });

      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async delete(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/item-answer/${id}`,
        method: "DELETE",
      });

      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}
