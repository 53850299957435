import React from "react";
import {makeGTFacilDialogUseCases} from "~/main/factories/use-cases/gt-facil-dialog";
import DialogTable from "~/presentation/views/Admin/GTFacil/Dialog/Table";

export const makeDialogTable = (params) => {
  return (
    <DialogTable
      {...params}
      gtFacildialogUseCases={makeGTFacilDialogUseCases()}
    />
  );
};
