import { handleErrors } from "~/presentation/utils/errorsHandling";
import { apiURL } from "../../usecases/api";
export class ApiTerm {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAll() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/searchterm`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        const handledError = handleErrors(err.response.data);
        throw handledError;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async getTermById(id) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/searchterm/${id}`,
        method: "GET",
      });

      return response.data;
    } catch (err) {
      if (err.response) {
        const handledError = handleErrors(err.response.data);
        throw handledError;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async createTerm(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/searchterm`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        const handledError = handleErrors(err.response.data);
        throw handledError;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async updateTerm(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/searchterm`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        const handledError = handleErrors(err.response.data);
        throw handledError;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async deleteTerm(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/searchterm/${id}`,
        method: "DELETE",
      });

      return response.data;
    } catch (err) {
      if (err.response) {
        const handledError = handleErrors(err.response.data);
        throw handledError;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }
}
