import { floatToReais, formatPercentageReport } from "~/presentation/utils/reais-parser";

export const reportMapper = (data, result) => {

  const report = {
    ...data,
    title: 'INSS',
    tax: 0,
    retentionValue: result,
    items: [
      {
        descriptionItem: "Valor da exclusão de materiais/equipamentos",
        value: floatToReais(data.materialOrEquipmentExclusionValue)
      },
      {
        descriptionItem: "Valor da dedução de vale-transporte ou despesas com alimentação",
        value: floatToReais(data.transportationOrAlimentationBenefitsDeductionValue)
      },
      {
        descriptionItem: "Base de cálculo da retenção previdenciária",
        value: floatToReais(data.baseRetentionCalcValue)
      },
      {
        descriptionItem: "Alíquota da retenção",
        //number * 1000 / 10 is necessary to prevent error in the decimals
        value: `${formatPercentageReport((data.aliquotRetention*1000)/10)}%`
      },
      {
        descriptionItem: "Valor da Retenção Principal",
        value: `${floatToReais(data.principalAliquotRentionValue)}`
      },
      {
        descriptionItem: "Alíquota adicional do art. 131 da IN RFB 2.110/2022",
        value: data.hasAdditionalAliquotRetention !== "false" ?  `${formatPercentageReport(data.additionalAliquotRetention*100)}%` : '0,0%'
      },
      {
        descriptionItem: "Valor da Retenção Adicional",
        value: `${floatToReais(data.additionalAliquotRetentionValue)}`
      },
      {
        descriptionItem: "Total da Retenção Previdenciária",
        value: floatToReais(result)
      }
    ]
  }
  return report;
}
