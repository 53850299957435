import { apiURL } from "../../usecases/api";
export class ApiLegalNature {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async list() {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/legalNature/`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
}
