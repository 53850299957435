import React from "react";

const CalculatorIcon = ({ height = 64, width = 64 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M48 53.18L42.82 48L40 50.82L48 58.82L62 44.82L59.18 42L48 53.18Z" fill="#0d62a5" />
      <path d="M30 46H34V50H30V46Z" fill="#0d62a5" />
      <path d="M18 46H22V50H18V46Z" fill="#0d62a5" />
      <path d="M42 36H46V40H42V36Z" fill="#0d62a5" />
      <path d="M30 36H34V40H30V36Z" fill="#0d62a5" />
      <path d="M18 36H22V40H18V36Z" fill="#0d62a5" />
      <path d="M42 26H46V30H42V26Z" fill="#0d62a5" />
      <path d="M30 26H34V30H30V26Z" fill="#0d62a5" />
      <path d="M18 26H22V30H18V26Z" fill="#0d62a5" />
      <path d="M18 14H46V20H18V14Z" fill="#0d62a5" />
      <path
        d="M34 60H12.01C10.9468 59.9989 9.92746 59.5761 9.17567 58.8243C8.42388 58.0725 8.00106 57.0532 8 55.99V7.992C8.00106 6.93358 8.42198 5.91881 9.1704 5.1704C9.91882 4.42198 10.9336 4.00106 11.992 4H52.008C53.0664 4.00106 54.0812 4.42198 54.8296 5.1704C55.578 5.91881 55.9989 6.93358 56 7.992V36H52V8H12V56H34V60Z"
        fill="#0d62a5"
      />
    </svg>
  );
};

const DialogIcon = ({ width = "64", height = "64" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="#0d62a5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.0001 4.44446C16.3201 4.44446 3.55566 15.6089 3.55566 29.3333C3.55566 43.0578 16.3201 54.2222 32.0001 54.2222C34.9337 54.2188 37.8529 53.8121 40.6757 53.0133L50.5068 59.2711C50.7752 59.4433 51.0849 59.5402 51.4036 59.5515C51.7223 59.5628 52.0381 59.4882 52.3181 59.3355C52.598 59.1828 52.8317 58.9577 52.9947 58.6836C53.1577 58.4095 53.244 58.0967 53.2446 57.7778V45.8133C55.469 43.6794 57.2441 41.1221 58.4653 38.2919C59.6866 35.4616 60.3293 32.4157 60.3557 29.3333C60.4446 15.6089 47.6801 4.44446 32.0001 4.44446ZM50.2934 43.7511C50.1147 43.9169 49.972 44.1176 49.8741 44.3409C49.7762 44.5641 49.7253 44.8051 49.7246 45.0489V54.5422L41.9379 49.5822C41.7207 49.4449 41.4763 49.3564 41.2216 49.3226C40.9668 49.2888 40.7078 49.3107 40.4623 49.3867C37.7223 50.2377 34.8693 50.6692 32.0001 50.6667C18.2757 50.6667 7.11122 41.1022 7.11122 29.3333C7.11122 17.5645 18.2757 8.00001 32.0001 8.00001C45.7246 8.00001 56.889 17.5645 56.889 29.3333C56.8485 32.0624 56.2413 34.7532 55.1059 37.2351C53.9705 39.7171 52.3317 41.936 50.2934 43.7511Z"
        fill="#0d62a5"
      />
      <path
        d="M44.4445 27.5555H19.5556C19.0841 27.5555 18.6319 27.7428 18.2985 28.0762C17.9651 28.4096 17.7778 28.8618 17.7778 29.3333C17.7778 29.8048 17.9651 30.257 18.2985 30.5904C18.6319 30.9238 19.0841 31.1111 19.5556 31.1111H44.4445C44.916 31.1111 45.3682 30.9238 45.7016 30.5904C46.035 30.257 46.2223 29.8048 46.2223 29.3333C46.2223 28.8618 46.035 28.4096 45.7016 28.0762C45.3682 27.7428 44.916 27.5555 44.4445 27.5555Z"
        fill="#0d62a5"
      />
      <path
        d="M38.6668 36.4445H25.3334C24.8619 36.4445 24.4098 36.6318 24.0764 36.9652C23.743 37.2986 23.5557 37.7507 23.5557 38.2222C23.5557 38.6937 23.743 39.1459 24.0764 39.4793C24.4098 39.8127 24.8619 40 25.3334 40H38.6668C39.1383 40 39.5905 39.8127 39.9239 39.4793C40.2573 39.1459 40.4446 38.6937 40.4446 38.2222C40.4446 37.7507 40.2573 37.2986 39.9239 36.9652C39.5905 36.6318 39.1383 36.4445 38.6668 36.4445Z"
        fill="#0d62a5"
      />
      <path
        d="M20.0532 22.2222H43.9465C44.418 22.2222 44.8702 22.0349 45.2036 21.7015C45.537 21.3681 45.7243 20.916 45.7243 20.4445C45.7243 19.973 45.537 19.5208 45.2036 19.1874C44.8702 18.854 44.418 18.6667 43.9465 18.6667H20.0532C19.5817 18.6667 19.1295 18.854 18.7961 19.1874C18.4627 19.5208 18.2754 19.973 18.2754 20.4445C18.2754 20.916 18.4627 21.3681 18.7961 21.7015C19.1295 22.0349 19.5817 22.2222 20.0532 22.2222Z"
        fill="#0d62a5"
      />
    </svg>
  );
};

const TextIcon = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M55.1113 60.4445H23.1113C22.6398 60.4445 22.1876 60.2572 21.8542 59.9238C21.5208 59.5904 21.3335 59.1382 21.3335 58.6667V19.5556C21.3335 19.0841 21.5208 18.6319 21.8542 18.2985C22.1876 17.9651 22.6398 17.7778 23.1113 17.7778H55.1113C55.5828 17.7778 56.035 17.9651 56.3684 18.2985C56.7018 18.6319 56.8891 19.0841 56.8891 19.5556V58.6667C56.8891 59.1382 56.7018 59.5904 56.3684 59.9238C56.035 60.2572 55.5828 60.4445 55.1113 60.4445ZM24.8891 56.8889H53.3335V21.3334H24.8891V56.8889Z"
        fill="#0d62a5"
      />
      <path d="M28.4448 28.4445H49.7782V32H28.4448V28.4445Z" fill="#0d62a5" />
      <path d="M28.4448 35.5555H49.7782V39.1111H28.4448V35.5555Z" fill="#0d62a5" />
      <path d="M28.4448 42.6666H49.7782V46.2222H28.4448V42.6666Z" fill="#0d62a5" />
      <path
        d="M10.6669 42.6667V7.1111H42.6669V5.33332C42.6669 4.86182 42.4796 4.40964 42.1462 4.07624C41.8128 3.74284 41.3606 3.55554 40.8891 3.55554H8.88911C8.41761 3.55554 7.96543 3.74284 7.63203 4.07624C7.29863 4.40964 7.11133 4.86182 7.11133 5.33332V44.4444C7.11133 44.9159 7.29863 45.3681 7.63203 45.7015C7.96543 46.0349 8.41761 46.2222 8.88911 46.2222H10.6669V42.6667Z"
        fill="#0d62a5"
      />
      <path
        d="M17.7782 49.7777V14.2222H49.7782V12.4444C49.7782 11.9729 49.5909 11.5207 49.2575 11.1873C48.9241 10.8539 48.4719 10.6666 48.0004 10.6666H16.0004C15.5289 10.6666 15.0768 10.8539 14.7434 11.1873C14.41 11.5207 14.2227 11.9729 14.2227 12.4444V51.5555C14.2227 52.027 14.41 52.4792 14.7434 52.8126C15.0768 53.146 15.5289 53.3333 16.0004 53.3333H17.7782V49.7777Z"
        fill="#0d62a5"
      />
    </svg>
  );
};

const TaskIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="64px"
      viewBox="0 -960 960 960"
      width="64px"
      fill="#FFF"
    >
      <path d="m434-254.76 230.91-231.15-40.67-40.68-190 190L331-439.83l-40.91 40.92L434-254.76ZM222.15-74.02q-27.6 0-47.86-20.27-20.27-20.26-20.27-47.86v-675.7q0-27.7 20.27-48.03 20.26-20.34 47.86-20.34h361.48l222.59 222.59v521.48q0 27.6-20.34 47.86-20.33 20.27-48.03 20.27h-515.7Zm326.7-557.83v-186h-326.7v675.7h515.7v-489.7h-189Zm-326.7-186v186-186 675.7-675.7Z" />
    </svg>
  );
};

const TaskIconColor = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="64px"
      viewBox="0 -960 960 960"
      width="64px"
      fill="#0d62a5"
    >
      <path d="m434-254.76 230.91-231.15-40.67-40.68-190 190L331-439.83l-40.91 40.92L434-254.76ZM222.15-74.02q-27.6 0-47.86-20.27-20.27-20.26-20.27-47.86v-675.7q0-27.7 20.27-48.03 20.26-20.34 47.86-20.34h361.48l222.59 222.59v521.48q0 27.6-20.34 47.86-20.33 20.27-48.03 20.27h-515.7Zm326.7-557.83v-186h-326.7v675.7h515.7v-489.7h-189Zm-326.7-186v186-186 675.7-675.7Z" />
    </svg>
  );
};

export { CalculatorIcon, DialogIcon, TextIcon, TaskIcon, TaskIconColor };
