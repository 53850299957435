import fakeDialogList from "./fake-dialog-list.json";
import { apiURL } from "../../usecases/api";
export class ApiGTFacilDialogUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async createDialog(data) {
    await this.httpClient.request({
      url: `${apiURL}/gt-facil/dialog`,
      method: "POST",
      data,
    });
  }

  async getDialogs() {
    let response = { data: fakeDialogList };
    try {
      response = await this.httpClient.request({
        url: `${apiURL}/gt-facil/dialog`,
        method: "GET",
      });
    } catch (error) {
      console.log(error);
    }

    response.data.forEach((dialog) => {
      if (dialog.text.length > 80) {
        dialog.text = `${dialog.text.substring(0, 80)}...`;
      }
      dialog.number = dialog.id;
    });

    return response.data;
  }

  async getById(id) {
    let response = { data: fakeDialogList.find((l) => l.id == id) };
    try {
      response = await this.httpClient.request({
        url: `${apiURL}/gt-facil/dialog/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return response.data;
  }

  async update(id, data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/gt-facil/dialog/${id}`,
      method: "PUT",
      data,
    });
    return response.data;
  }

  async delete(id) {
    const response = await this.httpClient.request({
      url: `${apiURL}/gt-facil/dialog/${id}`,
      method: "DELETE",
    });
    return response.data;
  }
}
