import * as Yup from "yup";

export const validationSchema = Yup.object({
  retentionAliquotPercentage: Yup.mixed()
    .test("Verification Retention", "A alíquota não pode ser 0%", function test(retention) {
      if (retention == 0.0) {
        return false;
      }
      return true;
    })
    .test(
      "Verification Retention",
      "O percentual mínimo autorizado no art. 8º da LC 116/2003 é de 2%.",
      function test(retention) {
        const selectedSubItem = localStorage.getItem("selected-subitem");

        if (
          selectedSubItem !== "7.02" &&
          selectedSubItem !== "7.05" &&
          selectedSubItem !== "16.01" &&
          retention >= 0.01 &&
          retention <= 1.99
        ) {
          return false;
        }

        return true;
      }
    )
    .test(
      "Verification Retention",
      "O percentual máximo autorizado no art. 8º da LC 116/2003 é de 5%.",
      function test(retention) {
        if (retention > 5.0) {
          return false;
        }

        return true;
      }
    ),
});
