import React from "react";
import Home from "~/presentation/views/LandingPage/Home";
import {makeAuthenticationUseCases} from "~/main/factories/use-cases/authentication";
import {makeContactUseCases} from "~/main/factories/use-cases/contact";
import {makePlanUseCases} from "~/main/factories/use-cases/plan";
import {makeCompanyUseCases} from "~/main/factories/use-cases/company";
import {makeSubscribeUseCases} from "~/main/factories/use-cases/subscribe";

export const makeLandingPageHome = (params) => {
  return (
    <Home
      {...params}
      authenticationUseCases={makeAuthenticationUseCases()}
      planUseCases={makePlanUseCases()}
      companyUseCases={makeCompanyUseCases()}
      contactUseCases={makeContactUseCases()}
      subscribeUseCases={makeSubscribeUseCases()}
    />
  );
};
