import React, { useContext, useState } from "react";
import { HeaderSectionPage } from "../components/HeaderSectionPage";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import { Box, Checkbox, FormControlLabel, MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid2";

const reportTypes = [
  {
    id: 0,
    label: "Retenções (IR, PIS, COFINS, CSLL, Agregado)",
    value: "IR",
    checkbox: {
      param: "isPayment",
      label: "Por data de pagamento",
      value: false,
    },
  },
  {
    id: 1,
    label: "Retenções de INSS",
    value: "INSS",
    checkbox: {
      param: "isPayment",
      label: "Por data de pagamento",
      value: false,
    },
  },
  {
    id: 2,
    label: "Retenções (IR, PIS, COFINS, CSLL, Agregado) por natureza de rendimento (R4020)",
    value: "R4020",
    checkbox: {
      param: "isSummary",
      label: "Resumo",
      value: false,
    },
  },
  {
    id: 3,
    label: "Retenções de INSS por código do serviço, do período informado (R2010)",
    value: "R2010",
    checkbox: {
      param: "isSummary",
      label: "Resumo",
      value: false,
    },
  },
];

function Reports() {
  const apiReinf = new ApiReinfUseCases(makeHttpClient());
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  const [selectedReport, setSelectedReport] = useState("");

  //valores padrão
  const today = new Date();
  const sixMonths = new Date(new Date().setMonth(new Date().getMonth() - 6));

  // Filters
  const [filter, setFilter] = useState({
    start: sixMonths,
    end: today,
    report: null,
  });

  const [pdfUrl, setPdfUrl] = useState("");

  const handleChangeFilter = (newValues) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...newValues,
    }));
  };

  //current time
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const handleGenarateReport = async () => {
    openBackdrop(true, "Gerando relatório");

    const reportRequestParams = {
      dateI: filter.start.toISOString().split("T")[0],
      dateF: filter.end.toISOString().split("T")[0],
    };

    switch (filter.report.checkbox.param) {
      case "isPayment":
        reportRequestParams.isPayment = filter.report.checkbox.value;
        break;

      case "isSummary":
        reportRequestParams.isSummary = filter.report.checkbox.value;
        break;

      default:
        break;
    }

    try {
      openBackdrop(true, "Carregando informação dos eventos");
      const response = await apiReinf.generateReport(filter.report.value, reportRequestParams);
      if (response) {
        setFeedbackSnackbar({
          isOpen: true,
          message: "Relatório gerado com sucesso",
          type: "success",
        });
        setPdfUrl(response);
      }
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro na geração do relatório",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const minDate = new Date(currentYear - 10, currentMonth, 1); //6 anos atrás
  const maxDate = new Date(); //Hoje

  //ERRO: Data inválida (FUTURO)
  const isStartDateInTheFuture = filter?.start > maxDate;
  const isEndDateInTheFuture = filter?.end > maxDate;
  const isDateWrongText = "Selecione uma data no presente.";
  //ERRO: Data inicial maior do que final
  const isStartDateHigherThanEnd = filter?.start > filter?.end;
  const isStartDateHigherThanEndText =
    isStartDateHigherThanEnd && "Data inicial superior à data final.";
  // ERRO: Data inválida (não totalmente preenchida)
  const isStartDateInvalid = !filter?.start || filter?.start == "Invalid Date";
  const isEndDateInvalid = !filter?.end || filter?.end == "Invalid Date";

  //INVALIDAR BOTÃO APLICAR
  const disabledBtn =
    isStartDateInTheFuture ||
    isEndDateInTheFuture ||
    isStartDateHigherThanEnd ||
    isStartDateInvalid ||
    isEndDateInvalid ||
    !selectedReport;

  return (
    <>
      <HeaderSectionPage title="Relatórios" />
      <Grid
        container
        spacing={2}
        sx={{
          paddingTop: 1,
          paddingInline: 3,
        }}
      >
        <Grid size={12} sx={styles.filterContainer}>
          <p style={{ fontSize: 12, fontStyle: "italic", marginBottom: -10 }}>Tipo de Relatório</p>
          <RButton
            type="filterSelect"
            title=""
            value={selectedReport}
            onChange={(option) => {
              setSelectedReport(option.target?.value);
              handleChangeFilter({
                report: reportTypes.find((reports) => reports.value === option.target?.value),
              });
            }}
          >
            {reportTypes.map((option) => (
              <MenuItem key={option.id} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </RButton>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3 }} sx={styles.filterContainer}>
          <p style={{ fontSize: 12, fontStyle: "italic" }}>Data Início</p>
          <Input
            styles={styles.dateInput}
            minDate={minDate}
            maxDate={filter?.end || maxDate}
            type="date"
            value={filter?.start}
            onChange={(date) => {
              handleChangeFilter({ start: date });
            }}
            error={(isStartDateInTheFuture && isDateWrongText) || isStartDateHigherThanEndText}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3 }} sx={styles.filterContainer}>
          <p style={{ fontSize: 12, fontStyle: "italic" }}>Data Final</p>
          <Input
            styles={styles.dateInput}
            minDate={filter?.start || minDate}
            maxDate={maxDate}
            type="date"
            value={filter?.end}
            onChange={(date) => {
              handleChangeFilter({ end: date });
            }}
            error={(isEndDateInTheFuture && isDateWrongText) || isStartDateHigherThanEndText}
          />
        </Grid>
        {selectedReport && (
          <Grid size={{ xs: 12, sm: 6 }} sx={{ ...styles.filterContainer, marginBottom: "12px" }}>
            <Box sx={styles.checkboxContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      reportTypes.find((option) => option.value === selectedReport)?.checkbox
                        ?.value || false
                    }
                    onChange={(e) => {
                      const currentReport = reportTypes.find(
                        (reports) => reports.value === selectedReport
                      );
                      currentReport.checkbox.value = e.target.checked;
                      handleChangeFilter({ report: { ...currentReport } });
                    }}
                  />
                }
                label={
                  reportTypes.find((option) => option.value === selectedReport)?.checkbox?.label ||
                  ""
                }
              />
            </Box>
          </Grid>
        )}
        <Grid
          size={{ xs: 12, sm: 6, md: 12 }}
          sx={{
            marginTop: "1.45rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RButton
            disabled={disabledBtn}
            onClick={handleGenarateReport}
            sx={{
              height: "45px",
              width: "15vw",
            }}
          >
            GERAR RELATÓRIO
          </RButton>
        </Grid>
        {pdfUrl && (
          <Grid
            size={12}
            sx={{
              marginTop: "1.45rem",
              marginBottom: "2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <iframe src={pdfUrl} width="100%" height="900px" title="Relatório PDF" />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default Reports;

const styles = {
  Button: {
    width: "fit-content",
    borderRadius: "8px",
    boxShadow: 0,
    textTransform: "uppercase",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 2,
    paddingLeft: 2,
    borderRadius: "5px",
    height: "40px",
    marginTop: "20px",
  },
  serachIcon: {
    color: "#1341A1",
  },
  dateInput: {
    backgroundColor: "#DCDCDC",
    borderRadius: "5px",
    height: "40px",
  },
};
