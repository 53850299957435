import React, { createContext, useMemo, useState } from "react";
import DialogBox from "../components/DialogBox";

export const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
  const [showDialogBox, setShowDialogBox] = useState({ open: false });

  const value = {
    openDialogBox: (props) => setShowDialogBox({ open: true, ...props }), // message, callback(), negativeCallback(), confirmText, denyText, customComponent,
  };

  return (
    <DialogContext.Provider value={useMemo(() => value, [])}>
      {children}
      <DialogBox config={showDialogBox} setDialogBox={setShowDialogBox} />
    </DialogContext.Provider>
  );
};
