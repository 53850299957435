import React, { useEffect, useState } from "react";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import {
  Container,
  Typography,
  Box,
  TableHead,
  Table,
  TableBody,
  TableCell,
  Checkbox,
} from "@mui/material";
import { TableRow } from "@mui/material";
import { formatNumberToRealString } from "~/presentation/views/Reinf/Utils/utilsCurrencyvalue";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import { formatDateToBrazilian } from "~/presentation/views/Reinf/Utils/utilsDate";

function AddMoreNfse({ onClose, protocol, validNfse, currentNfse, setData, QSO, selectedEvent }) {
  const CNPJcontratante = currentNfse[0]?.companyCNPJ;
  const CNPJcontratado = currentNfse[0]?.businessPartnerCNPJ;

  const [possibleNfseToAdd, setPossibleNfseToAdd] = useState({});

  //LINHAS SELECIONADAS
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    const handleSetValidNfse = () => {
      let result = [...validNfse];
      const current = [...currentNfse];
      result = result.filter(
        (item) =>
          item.businessPartnerCNPJ === CNPJcontratado && item.companyCNPJ === CNPJcontratante
      );
      result = result.filter((item) => !current.some((currentItem) => currentItem.id === item.id));
      setPossibleNfseToAdd(result);
    };

    handleSetValidNfse();
  }, [currentNfse]);

  //Selecionar TODAS as documentos
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedRows(possibleNfseToAdd);
    } else {
      setSelectedRows([]);
    }
  };

  //Selecionar nota individual
  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, item]);
    } else {
      setSelectedRows((prev) => prev.filter((selectedItem) => selectedItem !== item));
    }
  };

  //para por no checkbox de cada linha
  const isSelected = (item) => selectedRows.includes(item);

  //para o checkbox "titulo" do head
  const numSelected = selectedRows.length;
  const rowCount = possibleNfseToAdd.length;

  const handleAddNfseToEvent = () => {
    setData([...currentNfse, ...selectedRows]);
    onClose();
  };

  return (
    <Container sx={styles.content}>
      <Typography sx={styles.Title}>
        Adicionar mais docs. fiscais {protocol ? `ao evento ${protocol}` : "a este evento"}
      </Typography>
      <Box>
        {possibleNfseToAdd?.length > 0 ? (
          <Box sx={{ borderRadius: "10px", overflow: "hidden" }}>
            <Table sx={{ ...styles.Table, borderCollapse: "separate", borderSpacing: "0" }}>
              <TableHead>
                <TableRow sx={styles.HeadTableRow}>
                  <TableCell sx={{ ...styles.TableCellHead, padding: 0 }}>
                    <Checkbox
                      color="default"
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                  <TableCell sx={styles.TableCellHead}>Nº do Doc</TableCell>
                  <TableCell sx={styles.TableCellHead}>CNPJ/CPF Contratado</TableCell>
                  <TableCell sx={styles.TableCellHead}>
                    {selectedEvent === "r2010" ? "Data de Emissão" : "  Data de Pagamento"}
                  </TableCell>
                  <TableCell sx={styles.TableCellHead}>Valor da Operação</TableCell>
                  <TableCell sx={styles.TableCellHead}>
                    {selectedEvent === "r2010"
                      ? "Base INSS"
                      : QSO === "A" || QSO === "B"
                        ? "Base IRPJ"
                        : QSO === "C"
                          ? "Base IR"
                          : "Ret. IR"}
                  </TableCell>
                  <TableCell sx={styles.TableCellHead}>
                    {selectedEvent === "r2010"
                      ? "Ret. INSS"
                      : QSO === "A" || QSO === "B"
                        ? "Ret. Base"
                        : QSO === "C"
                          ? "Ret. IR"
                          : "Ret. CSRF"}
                  </TableCell>
                  <TableCell sx={styles.TableCellHead}>CNPJ/CPF Contratante</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {possibleNfseToAdd.map((item, index) => (
                  <React.Fragment key={index}>
                    <TableRow sx={styles.TableRow}>
                      <TableCell sx={{ ...styles.TableCell, padding: 0 }}>
                        <Checkbox
                          color="default"
                          onChange={(event) => handleCheckboxChange(event, item)}
                          checked={isSelected(item)}
                        />
                      </TableCell>
                      <TableCell sx={styles.TableCell}>{item.nFseNumber}</TableCell>
                      <TableCell sx={styles.TableCell}>
                        {formatCpfCnpj(item.businessPartnerCNPJ)}
                      </TableCell>
                      <TableCell sx={styles.TableCell}>
                        {formatDateToBrazilian(
                          selectedEvent === "r2010" ? item.dateOfIssue : item.dateOfPayment
                        )}
                      </TableCell>
                      <TableCell sx={styles.TableCell}>
                        {formatNumberToRealString(item.amountOperation)}
                      </TableCell>
                      <TableCell sx={styles.TableCell}>
                        {formatNumberToRealString(
                          selectedEvent === "r2010"
                            ? item.bcINSS
                            : QSO === "A" || QSO === "B"
                              ? item.bcIR
                              : QSO === "C"
                                ? item.bcIR
                                : item.amountIR
                        )}
                      </TableCell>
                      <TableCell sx={styles.TableCell}>
                        {formatNumberToRealString(
                          selectedEvent === "r2010"
                            ? item.amountINSS
                            : QSO === "A" || QSO === "B"
                              ? item.amountAggregate
                              : QSO === "C"
                                ? item.amountIR
                                : item.amountCSRF
                        )}
                      </TableCell>
                      <TableCell sx={styles.TableCell}>{formatCpfCnpj(item.companyCNPJ)}</TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </Box>
        ) : (
          <Box>Não existem documentos possíveis de adição neste evento.</Box>
        )}
      </Box>
      {possibleNfseToAdd.length > 0 ? (
        <Box sx={styles.bottom}>
          <Typography sx={styles.text}>
            <b>Documentos selecionados:</b> {selectedRows.length}
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <RButton variant="outlined" onClick={onClose}>
              CANCELAR
            </RButton>
            <RButton onClick={handleAddNfseToEvent}>ADICIONAR</RButton>
          </Box>
        </Box>
      ) : (
        <Box sx={{ marginLeft: "auto", width: "fit-content", marginBlock: 2 }}>
          <RButton variant="outlined" onClick={onClose}>
            CANCELAR
          </RButton>
        </Box>
      )}
    </Container>
  );
}

export default AddMoreNfse;

const styles = {
  content: {
    overflowX: "auto",
    padding: 2,
  },
  Title: {
    fontSize: 20,
    fontWeight: 700,
    fontFamily: "Montserrat",
    marginBlock: 2,
  },
  text: {
    fontFamily: "Montserrat",
  },
  Table: {
    root: {},
  },
  //LINHA TÍTULOS COLUNAS
  HeadTableRow: {},
  //LINHA TÍTULOS DAS COLUANS
  TableCellHead: {
    backgroundColor: "#1341A1",
    fontFamily: "Montserrat",
    textAlign: "center",
    color: "#ffffff",
    fontSize: 13,
    padding: "10px",
    fontWeight: 400,
    position: "relative",
    //DIVISOR COLUNAS HEAD
    "&:not(:first-child)::after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "10%",
      bottom: "10%",
      left: 0,
      width: "3px",
      backgroundColor: "#142F68",
    },
  },
  //CADA LINHA (NOTA)
  TableCell: {
    fontFamily: "Montserrat",
    fontSize: 14,
    padding: "6px",
    color: "#000000",
    textAlign: "center",
    backgroundColor: "#D9D9D9",
  },
  //CADA LINHA
  TableRow: {},
  //documentos selecionados + botoes
  bottom: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 1,
    marginBlock: 3,
  },
};
