import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import Grid from "@mui/material/Grid2";
import { Container, Typography as Text, Box } from "@mui/material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import CloseIcon from "@mui/icons-material/Close";
import { ApiIncomeExemptionUseCases } from "~/data/usecases/IncomeExemption/incomeExemption";

export const IncomeExemptionModal = ({ payData, incomeExOptions, handleClose, initialValue }) => {
  const apiIncomeEx = new ApiIncomeExemptionUseCases(makeHttpClient());
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const [values, setValues] = useState({
    id: 0,
    paymentRecordId: payData?.id,
    exemptionType: null,
    amount: 0,
    descriptionExemption: "",
    dateOfIllness: null,
  });
  //se valor inicial, carrega os dados para edição
  useEffect(() => {
    if (initialValue) {
      setValues(initialValue);
    }
  }, [initialValue]);

  const handleChange = (name, value) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    openBackdrop(true, "Informações de isenção...");
    try {
      const response = initialValue
        ? await apiIncomeEx.updateIncomeExemption(values)
        : await apiIncomeEx.createIncomeExemption(values);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Dados de Isenção salvos com sucesso",
        type: "success",
      });
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: `Erro ao atualizar Isenção. ${error?.data?.response}`,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <Container style={styles.Form}>
      <Grid container spacing={3}>
        <Grid size={{ xs: 6, sm: 11 }}>
          <Text style={styles.Title}>Rendimentos Isentos</Text>
        </Grid>
        <Grid
          size={{ xs: 6, md: 1 }}
          sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
        >
          <CloseIcon onClick={handleClose} />
        </Grid>
        <Grid size={{ xs: 12, sm: values?.exemptionType === 6 ? 6 : 9 }}>
          <Input
            type="select"
            title="Tipo de Isenção"
            options={incomeExOptions}
            placeholder="Selecionar tipo de dedução"
            value={incomeExOptions.find((item) => item.code === values.exemptionType)}
            getOptionLabel={(option) => `${option.code} - ${option.description}`}
            onChange={(value) => handleChange("exemptionType", value.code)}
            error={!values?.exemptionType && "Campo Obrigatório"}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: values?.exemptionType === 6 ? 3 : 3 }}>
          <Input
            type="amount"
            title="Valor"
            value={values?.amount || ""}
            onChange={(e) => handleChange("amount", e)}
            error={!values?.amount && "Campo obrigatório"}
          />
        </Grid>
        {values?.exemptionType === 6 && (
          <Grid size={{ xs: 12, sm: 3 }}>
            <Input
              type="date"
              title="Data do Laudo"
              value={values?.dateOfIllness || ""}
              onChange={(e) => handleChange("dateOfIllness", e)}
            />
          </Grid>
        )}
        <Grid size={12}>
          <Input
            title="Descrição"
            type="textarea"
            minRows={5}
            maxlength="500"
            value={values?.descriptionExemption || ""}
            onChange={(e) => handleChange("descriptionExemption", e.target.value)}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", gap: 1, marginTop: 4 }}>
        <RButton sx={{ width: "150px" }} type="button" variant="outlined" onClick={handleClose}>
          Cancelar
        </RButton>
        <RButton
          sx={{ width: "150px" }}
          onClick={handleSubmit}
          disabled={!values?.exemptionType || !values?.amount}
        >
          {initialValue ? "Atualizar" : "Adicionar"}
        </RButton>
      </Box>
    </Container>
  );
};

const styles = {
  Form: {
    padding: "2rem",
  },
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  uploadText: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    fontWeight: "500",
    fontSize: 14,
    cursor: "pointer",
    padding: "6px 16px",
    borderRadius: "6px",
    color: "#fff",
    fontFamily: "Montserrat",
  },
  FileReader: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,

    left: 0,
    fontFamily: "Montserrat",
  },
  text: {
    color: "#d32f2f",
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "Montserrat",
  },
};
