import { apiURL } from "../../usecases/api";
export class ApiAnnotationUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAnnotations() {
    let response = await this.httpClient.request({
      url: `${apiURL}/Annotation`,
      method: "GET",
    });
    return response;
  }

  async getAnnotation(id) {
    const response = await this.httpClient.request({
      url: `${apiURL}/Annotation/${id}`,
      method: "GET",
    });
    return response;
  }

  async updateAnnotation(data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/Annotation`,
      method: "PUT",
      data,
    });
    return response;
  }

  async createAnnotation(data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/Annotation`,
      method: "POST",
      data,
    });
    return response;
  }

  async deleteAnnotation(id) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Annotation/${id}`,
        method: "DELETE",
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}
