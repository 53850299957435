import React from "react";
import { makeCompanyUseCases } from "~/main/factories/use-cases/company";
import { makeUserUseCases } from "~/main/factories/use-cases/user";
import User from "~/presentation/views/Admin/User/User";

export const makeUserTable = (params) => {
  return (
    <User {...params} userUseCases={makeUserUseCases()} companyUseCases={makeCompanyUseCases()} />
  );
};
