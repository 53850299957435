import { apiURL } from "../../usecases/api";
export class ApiContactUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async contactForm(values) {
    await this.httpClient.request({
      url: `${apiURL}/contactus`,
      method: "POST",
      data: values,
    });
  }

  async newsletterSubscribe(email) {
    await this.httpClient.request({
      url: `${apiURL}/newsletter-subscribe`,
      method: "POST",
      data: {
        email,
      },
    });
  }

  async subscription(data) {
    const apiData = {
      categoryId: data.category.value,
      planId: data.plan.value,
      company: data.company,
      email: data.email,
      name: data.name,
      phone: data.phone,
    };

    await this.httpClient.request({
      url: `${apiURL}/subscription`,
      method: "POST",
      data: apiData,
    });
  }
}
