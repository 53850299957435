export const getDependentsValue = (values) => {
  return values.numberOfDependents.value * 189.59;
};

export const getBaseCalculus = (values, simulatorValue) => {
  const { isTransportService, transportType, foodPensionValue, socialSecurityMonthPaymentValue } =
    values;

  let aliquot = 1;

  if (isTransportService === "true") {
    if (transportType === "passenger") {
      aliquot = 0.6;
    }
    if (transportType === "cargo") {
      aliquot = 0.1;
    }
  }

  const sumOfDependentsFoodPensionAndSocialSecurity =
    getDependentsValue(values) + foodPensionValue + socialSecurityMonthPaymentValue;

  const dedution =
    sumOfDependentsFoodPensionAndSocialSecurity > 0
      ? sumOfDependentsFoodPensionAndSocialSecurity
      : 0;

  const base = simulatorValue * aliquot - dedution;

  return base > 0 ? base : 0;
};

export const getIrrfResult = (values, simulatorValue, retentionAliquot) => {
  if (retentionAliquot == 0.0) {
    return 0;
  }
  let dedutionParcel = 0;
  switch (retentionAliquot) {
    case 0.075:
      dedutionParcel = 158.4;
      break;
    case 0.15:
      dedutionParcel = 370.4;
      break;
    case 0.225:
      dedutionParcel = 651.73;
      break;
    case 0.275:
      dedutionParcel = 884.96;
      break;
    default:
      break;
  }

  const bc = getBaseCalculus(values, simulatorValue);
  const result = bc * retentionAliquot - dedutionParcel;
  return result > 0 ? result : 0;
};
