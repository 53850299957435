import React from "react";
import {Container} from "./styles";

const SectionHeader = ({text, color, ...props}) => {
  return (
    <Container {...props} color={color}>
      <div>
        <span />
        <h2>{text}</h2>
        <span />
      </div>
    </Container>
  );
};

export default SectionHeader;
