import React from "react";
import TextInput from "~/presentation/components/TextInput";
import { Autocomplete } from "@mui/material";

const AutocompleteSelect = ({ label, inputProps, isDisabled, ...props }) => {
  return (
    <Autocomplete
      disabled={isDisabled}
      renderInput={(params) => (
        <TextInput {...params} {...inputProps} label={label} variant="outlined" />
      )}
      {...props}
    />
  );
};

export default AutocompleteSelect;
