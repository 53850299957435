export const dataToSelectMapper = (
  data,
  valueKey = "id",
  labelKey = "name" || "title",
) => {
  return data?.map((category) => {
    category.value = category[valueKey];
    category.label = category[labelKey];
    return category;
  });
};
