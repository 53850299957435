import React from "react";
// components
import { Box, IconButton, Tooltip } from "@mui/material";
// icons
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AddIcon from "@mui/icons-material/Add";
import { UploadFileOutlined } from "@mui/icons-material";

const TableActions = ({
  user,
  handleDelete,
  handleEdit,
  handlePromoteOrDemote,
  handleCopy,
  handleSubscribe,
  handleSend,
}) => (
  <Box sx={styles.container}>
    {handleEdit && (
      <Tooltip title="Editar">
        <IconButton onClick={handleEdit}>
          <EditIcon sx={styles.hoverOrange} />
        </IconButton>
      </Tooltip>
    )}
    {handleSend && (
      <Tooltip title="Efetuar envio">
        <IconButton onClick={handleSend}>
          <UploadFileOutlined sx={styles.hoverBlue} />
        </IconButton>
      </Tooltip>
    )}
    {handleDelete && (
      <Tooltip title="Eliminar">
        <IconButton onClick={handleDelete}>
          <DeleteOutlineIcon sx={styles.hoverRed} />
        </IconButton>
      </Tooltip>
    )}
    {handlePromoteOrDemote && (
      <Tooltip title={user.role === "user" ? "Promover" : "Despromover"}>
        <IconButton onClick={handlePromoteOrDemote} sx={styles.hoverYellow}>
          {user.role === "user" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        </IconButton>
      </Tooltip>
    )}
    {handleCopy && (
      <Tooltip title="Copiar dados">
        <IconButton onClick={handleCopy} sx={styles.hoverOrange}>
          <FileCopyIcon />
        </IconButton>
      </Tooltip>
    )}
    {handleSubscribe && (
      <Tooltip title="Subscrever">
        <IconButton onClick={handleSubscribe}>
          <AddIcon sx={styles.hoverYellow} />
        </IconButton>
      </Tooltip>
    )}
  </Box>
);

export default TableActions;

const styles = {
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  hoverRed: {
    "&:hover": {
      color: "#d32f2f",
    },
  },
  hoverOrange: {
    "&:hover": {
      color: "#ff6829",
    },
  },
  hoverYellow: {
    "&:hover": {
      color: "#ffb22b",
    },
  },
  hoverBlue: {
    "&:hover": {
      color: "#1341A1",
    },
  },
};
