import React, { useEffect, useState } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { Box } from "@mui/material";
import { EditPaymentPerson } from "./EditPaymentPerson";
import { ApiBusinessPartnerUseCases } from "~/data/usecases/businessPartner/businessPartner";

export default function RenderPaymentLayout({
  data, // O objeto específico da linha
  businessPartnerOptions,
  incomeNatureOptions,
  refreshDatePay,
}) {
  const apiBusinessPartner = new ApiBusinessPartnerUseCases(makeHttpClient());
  //options
  const [partners, setPartners] = useState([]);

  const getPartners = async () => {
    const result = await apiBusinessPartner.listBusinessPartner();
    setPartners(result);
  };

  useEffect(() => {
    getPartners();
  }, []);

  return (
    <Box sx={styles.Container}>
      <EditPaymentPerson
        payIdData={data} // Aqui estamos passando o objeto da linha clicada
        partners={partners}
        incomeNatureOptions={incomeNatureOptions}
        businessPartnerOptions={businessPartnerOptions}
        refreshDatePay={refreshDatePay}
      />
    </Box>
  );
}

const styles = {
  Container: {
    backgroundColor: "transparent",
    padding: 3,
  },
  ButtonBox: {
    position: "fixed",
    zIndex: 999,
    right: "10px",
    display: "flex",
    flexDirection: "column",
    gap: 1,
    bottom: "90px",
    background: "white",
    borderRadius: "10px",
    padding: 1,
    border: "1px solid blue",
  },
};
