import React from "react";
import { Container } from "~/presentation/components/MessageFromToSubject/styles";

const MessageFromToSubject = ({ taxInfo }) => {
  const fileIsValid = (files) => Array.isArray(files) && files.length > 0;

  const getStatus = (status) => {
    if (status === "Finished") {
      return "Finalizada";
    }

    return "Pendente";
  };

  return (
    <Container>
      {taxInfo?.authorName && (
        <div>
          <small>De:</small>
          <span>{taxInfo.authorName}</span>
        </div>
      )}
      {taxInfo?.email && (
        <div>
          <small>Email:</small>
          <span>{taxInfo.email}</span>
        </div>
      )}
      {taxInfo?.subject && (
        <div>
          <small>Assunto:</small>
          <span>{taxInfo.subject}</span>
        </div>
      )}
      {taxInfo?.message && (
        <div>
          <small>Mensagem:</small>
          <span>{taxInfo.message}</span>
        </div>
      )}
      {taxInfo?.publishDate && (
        <div>
          <small>Data:</small>
          <span>
            {new Date(`${taxInfo.publishDate}Z`).toLocaleDateString()}{" "}
            {new Date(`${taxInfo.publishDate}Z`).toLocaleTimeString()}
          </span>
        </div>
      )}
      {taxInfo?.statusText && (
        <div>
          <small>Status:</small>
          <span>{getStatus(taxInfo?.statusText)}</span>
        </div>
      )}
      {fileIsValid(taxInfo?.files) && <small>Arquivos:</small>}
      {fileIsValid(taxInfo?.files) &&
        taxInfo?.files.map((file) => {
          return (
            <a key={file.url} href={file.url} target="_blank" rel="noreferrer">
              {file.filename}
            </a>
          );
        })}
      <hr />
    </Container>
  );
};

export default MessageFromToSubject;
