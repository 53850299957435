const hiredTypes = {
  individual: 1,
  PJ: 2,
  cooperative: 3,
  mei: 4,
};

export class HiredType {
  constructor(type) {
    this.type = type;
    this.isSimples = undefined;
  }

  isPJNotSimples() {
    return this.type.id === hiredTypes.PJ && this.isSimples === false;
  }

  isPJSimples() {
    return this.type.id === hiredTypes.PJ && this.isSimples === true;
  }

  isMEI() {
    return this.type.id === hiredTypes.mei;
  }

  isCooperative() {
    return this.type.id === hiredTypes.cooperative;
  }

  isIndividual() {
    return this.type.id === hiredTypes.individual;
  }

  setIsSimples(isSimples) {
    this.isSimples = isSimples;
  }

  get name() {
    return this.type.name;
  }
}
