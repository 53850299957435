import React from "react";
import { makeBlogUseCase } from "~/main/factories/use-cases/blog";
import { makeBlogCategoryUseCases } from "~/main/factories/use-cases/blog-category";
import Blog from "~/presentation/views/Admin/Blog/Blog";

export const makeBlogTable = (params) => {
  return (
    <Blog
      {...params}
      blogUseCases={makeBlogUseCase()}
      blogCategoryUseCases={makeBlogCategoryUseCases()}
    />
  );
};
