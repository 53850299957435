import { apiURL } from "../../usecases/api";
export class ApiISSUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async saveAnswer(file) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await this.httpClient.request({
      url: `${apiURL}/iss/answers`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  }

  async saveStandardAnswer(file) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await this.httpClient.request({
      url: `${apiURL}/iss/standard-answers`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  }

  async saveCities(file) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await this.httpClient.request({
      url: `${apiURL}/Admin/ISS/saveCities`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  }
}
