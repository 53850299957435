import * as Yup from "yup";

export const validationR2010 = Yup.array().of(
  Yup.object({
    amountBC: Yup.number("É necessário ser um número!")
      .positive("Campo Obrigatório!")
      .required("Campo Obrigatório!")
      .typeError("Campo obrigatório!"),
    itemsNFse: Yup.array().of(
      Yup.object({
        serviceClassificationId: Yup.number("Campo Obrigatório!")
          .positive("Campo Obrigatório!")
          .required("Campo Obrigatório!"),
        bc: Yup.number("É necessário ser um número!")
          .positive("Campo Obrigatório!")
          .required("Campo Obrigatório!")
          .typeError("Campo obrigatório!"),
        amount: Yup.number("É necessário ser um número!")
          .positive("Campo Obrigatório!")
          .required("Campo Obrigatório!")
          .typeError("Campo obrigatório!"),
      })
    ),
  })
);

export const validationR4020AB = Yup.array().of(
  Yup.object({
    amountBC: Yup.number("É necessário ser um número!")
      .positive("Campo Obrigatório!")
      .required("Campo Obrigatório!")
      .typeError("Campo obrigatório!"),
    incomeNatureId: Yup.number("É necessário ser um número!")
      .typeError("Campo obrigatório!")
      .positive("Campo Obrigatório!")
      .required("Campo Obrigatório!"),
    dateOfPayment: Yup.date("Data inválida!")
      .typeError("Campo obrigatório!")
      .max(new Date(), "Escolha uma data no presente!")
      .required("Campo Obrigatório!"),
  })
);

export const validationR4020C = Yup.array().of(
  Yup.object({
    amountBC: Yup.number("É necessário ser um número!")
      .positive("Campo Obrigatório!")
      .required("Campo Obrigatório!")
      .typeError("Campo obrigatório!"),
    incomeNatureId: Yup.number("É necessário ser um número!")
      .typeError("Campo obrigatório!")
      .positive("Campo Obrigatório!")
      .required("Campo Obrigatório!"),
    dateOfPayment: Yup.date("Data inválida!")
      .typeError("Campo obrigatório!")
      .max(new Date(), "Escolha uma data no presente!")
      .required("Campo Obrigatório!"),
    bcIR: Yup.number("É necessário ser um número!")
      .positive("Campo Obrigatório!")
      .required("Campo Obrigatório!")
      .typeError("Campo obrigatório!"),
  })
);

export const validationR4020D = Yup.array().of(
  Yup.object({
    amountBC: Yup.number("É necessário ser um número!")
      .positive("Campo Obrigatório!")
      .required("Campo Obrigatório!")
      .typeError("Campo obrigatório!"),
    incomeNatureId: Yup.number("É necessário ser um número!")
      .typeError("Campo obrigatório!")
      .positive("Campo Obrigatório!")
      .required("Campo Obrigatório!"),
    dateOfPayment: Yup.date("Data inválida!")
      .typeError("Campo obrigatório!")
      .max(new Date(), "Escolha uma data no presente!")
      .required("Campo Obrigatório!"),
    bcIR: Yup.number("É necessário ser um número!")
      .positive("Campo Obrigatório!")
      .required("Campo Obrigatório!")
      .typeError("Campo obrigatório!"),
    bcCSRF: Yup.number("É necessário ser um número!")
      .positive("Campo Obrigatório!")
      .required("Campo Obrigatório!")
      .typeError("Campo obrigatório!"),
  })
);
