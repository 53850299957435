import React, { createContext, useMemo, useState } from "react";
import BackdropLoading from "../components/BackdropLoading";

export const BackdropContext = createContext();

export const BackdropProvider = ({ children }) => {
  const [backdrop, setBackdrop] = useState({
    open: false,
    msg: "",
  });

  const openBackdrop = (open, msg = "") => {
    setBackdrop({ open, msg });
  };

  const value = { openBackdrop };

  return (
    <BackdropContext.Provider value={useMemo(() => value, [])}>
      {children}
      <BackdropLoading open={backdrop.open} message={backdrop.msg} openBackdrop={openBackdrop} />
    </BackdropContext.Provider>
  );
};
