export class CompanyCategory {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  inRange(min, max) {
    return this.id >= min && this.id <= max;
  }
}
