import { floatToReais } from "~/presentation/utils/reais-parser";

export const reportMapper = (data, result) => {
  const report = {
    title: "ISS",
    tax: 3,
    retentionValue: result,
    items: [
      {
        descriptionItem:
          "Valor da exclusão de materiais, subempreitadas ou outras parcelas da base de cálculo",
        value: floatToReais(data.exclusionValue),
      },
      {
        descriptionItem: "Base de cálculo da retenção do ISS",
        value: floatToReais(data.baseRetentionCalcValue),
      },
      {
        descriptionItem: "Alíquota da retenção",
        value: `${data.retentionAliquotPercentage}%`,
      },
      {
        descriptionItem: "Valor da retenção do ISS",
        value: floatToReais(result),
      },
    ],
  };
  return report;
};
