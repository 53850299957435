import React from "react";
import { Tooltip, styled, tooltipClasses } from "@mui/material";

export default function OpenTooltip({
  children,
  text,
  placement,
  itemID,
  styles,
  backgroundColor,
  color,
  arrow,
}) {
  const TooltipWithStyle = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 300,
      backgroundColor: backgroundColor || "#222222",
      color: color || "white",
      padding: 12,
      textAlign: "justify",
      fontSize: 12,
      fontWeight: 300,
      fontFamily: "Montserrat",
      ...styles, //qualquer styles pode ser aplicado
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: backgroundColor || "#222222",
    },
  });

  return (
    <TooltipWithStyle arrow={arrow} itemID={itemID} title={text} placement={placement}>
      {children}
    </TooltipWithStyle>
  );
}
