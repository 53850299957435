import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { useDependentsOptions } from "~/presentation/views/Reinf/Utils/utilsOptionsReinf";
import { Container, Typography as Text, Box, Checkbox, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import CloseIcon from "@mui/icons-material/Close";
import { ApiDependentUseCases } from "~/data/usecases/dependent/apiDependent";
import { validarCPF } from "~/presentation/functions/cpfValidator";

export const DependentModal = ({ payData, handleClose, initialValue }) => {
  const { dependentsOptions } = useDependentsOptions();
  const apiDependent = new ApiDependentUseCases(makeHttpClient());
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const [values, setValues] = useState({
    id: 0,
    businessPartnerId: payData?.businessPartnerId || payData?.id, //"id" para o caso da tela de parceiro de negocio.
    cpf: "",
    dependentType: null,
    descriptionType: "",
    isPension: false,
  });

  //se houver valor inicial
  useEffect(() => {
    if (initialValue) {
      setValues(initialValue);
    }
  }, [initialValue]);

  const handleChange = (name, value) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    openBackdrop(true, "Cadastrando Informações de dependentes...");
    try {
      initialValue
        ? await apiDependent.updateDependent(values)
        : await apiDependent.createDependent(values);
      setFeedbackSnackbar({
        isOpen: true,
        message: "dependentes atualizados com sucesso",
        type: "success",
      });
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: `Erro ao atualizar dependentes. ${error?.response?.data}`,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <Container style={styles.Form}>
      <Grid container spacing={3}>
        <Grid size={{ xs: 6, sm: 11 }}>
          <Text style={styles.Title}>Dependentes do beneficiário</Text>
        </Grid>
        <Grid
          size={{ xs: 6, md: 1 }}
          sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
        >
          <CloseIcon onClick={handleClose} />
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Input
            type="mask"
            mask={"000.000.000-00"}
            placeholder="000.000.000-00"
            title="CPF"
            value={values?.cpf}
            onChange={(e) => handleChange("cpf", e)}
            error={!validarCPF(values?.cpf) && "Digite um CPF válido"}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 8 }}>
          <Input
            type="select"
            title="Relação de dependência"
            options={dependentsOptions}
            placeholder="Selecionar relação de dependência"
            value={dependentsOptions.find((item) => item.code === values.dependentType)}
            getOptionLabel={(option) => `${option.code} - ${option.description}`}
            onChange={(value) => handleChange("dependentType", value.code)}
            error={!values?.dependentType && "Campo obrigatório"}
          />
        </Grid>
        {values.dependentType === 99 && (
          <Grid size={12}>
            <Input
              title="Descrição do dependente"
              type="text"
              value={values?.descriptionType}
              onChange={(e) => handleChange("descriptionType", e.target.value)}
            />
          </Grid>
        )}
        <Grid size={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values?.isPension || false}
                onChange={(e) => handleChange("isPension", e.target.checked)}
              />
            }
            label="Dependentes e beneficiários da pensão alimentícia"
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", gap: 1, marginTop: 4 }}>
        <RButton sx={{ width: "150px" }} type="button" variant="outlined" onClick={handleClose}>
          Cancelar
        </RButton>
        <RButton
          sx={{ width: "150px" }}
          onClick={handleSubmit}
          disabled={!values?.cpf || !values?.dependentType}
        >
          {initialValue ? "Atualizar" : "Adicionar"}
        </RButton>
      </Box>
    </Container>
  );
};

const styles = {
  Form: {
    padding: "2rem",
  },
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  uploadText: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    fontWeight: "500",
    fontSize: 14,
    cursor: "pointer",
    padding: "6px 16px",
    borderRadius: "6px",
    color: "#fff",
    fontFamily: "Montserrat",
  },
  FileReader: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,

    left: 0,
    fontFamily: "Montserrat",
  },
  text: {
    color: "#d32f2f",
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "Montserrat",
  },
};
