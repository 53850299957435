import React from "react";

const CalculatorIcon = ({height = 64, width = 64}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48 53.18L42.82 48L40 50.82L48 58.82L62 44.82L59.18 42L48 53.18Z"
        fill="white"
      />
      <path d="M30 46H34V50H30V46Z" fill="white" />
      <path d="M18 46H22V50H18V46Z" fill="white" />
      <path d="M42 36H46V40H42V36Z" fill="white" />
      <path d="M30 36H34V40H30V36Z" fill="white" />
      <path d="M18 36H22V40H18V36Z" fill="white" />
      <path d="M42 26H46V30H42V26Z" fill="white" />
      <path d="M30 26H34V30H30V26Z" fill="white" />
      <path d="M18 26H22V30H18V26Z" fill="white" />
      <path d="M18 14H46V20H18V14Z" fill="white" />
      <path
        d="M34 60H12.01C10.9468 59.9989 9.92746 59.5761 9.17567 58.8243C8.42388 58.0725 8.00106 57.0532 8 55.99V7.992C8.00106 6.93358 8.42198 5.91881 9.1704 5.1704C9.91882 4.42198 10.9336 4.00106 11.992 4H52.008C53.0664 4.00106 54.0812 4.42198 54.8296 5.1704C55.578 5.91881 55.9989 6.93358 56 7.992V36H52V8H12V56H34V60Z"
        fill="white"
      />
    </svg>
  );
};

export default CalculatorIcon;
