import React, { useContext, useState } from "react";
import { Typography as Text, Box, CircularProgress } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import RButton from "../../Common/Buttons/RButton";
import { InsertDriveFileOutlined } from "@mui/icons-material";
import Input from "../../Common/Inputs";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import Grid from "@mui/material/Grid2";

function SIAFI({ handleSIAFI, files, setFiles, handleClose }) {
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [xmlFile, setXmlFile] = useState(null);
  const [xlsxFile, setXlsxFile] = useState(null);

  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);

  const handleVerifyDates = () => {
    const startYear = initialDate.getFullYear();
    const startMonth = initialDate.getMonth();
    const startDay = initialDate.getDate();

    const endYear = finalDate.getFullYear();
    const endMonth = finalDate.getMonth();
    const endDay = finalDate.getDate();

    const monthDifference = (endYear - startYear) * 12 + (endMonth - startMonth);

    if (monthDifference < 2) {
      submit();
    } else if (monthDifference === 2 && endDay <= startDay) {
      submit();
    } else {
      setFeedbackSnackbar({
        isOpen: true,
        message: "O período tem de ser no máximo de 2 meses.",
        type: "error",
      });
    }
  };

  const submit = () => {
    handleSIAFI(xmlFile, xlsxFile, initialDate, finalDate);
  };

  const importBtnDisabled =
    !xmlFile || !xlsxFile || files[0]?.fileXML || files[0]?.fileXLSL || !finalDate || !initialDate;

  const handleSIAFIChange = (event) => {
    const { id, files } = event.target;
    if (files.length > 0) {
      if (id === "xml-file-input") {
        setXmlFile(files[0]);
      } else if (id === "xlsx-file-input") {
        setXlsxFile(files[0]);
      }
    }
  };

  const handleCancel = () => {
    setXlsxFile(null);
    setXmlFile(null);
    setFiles([]);
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginBottom: 2, p: 2, maxHeight: "25vh", overflowY: "auto" }}>
        {files.map((file, index) => (
          <Grid size={12} key={index}>
            <Grid container sx={styles.containerFields}>
              <Grid size={12} sx={styles.fields}>
                {file.name && (
                  <Box
                    sx={{
                      ...styles.fieldsText,
                      maxWidth: "100%",
                      "@media (min-width: 600px)": {
                        maxWidth: "60%",
                      },
                    }}
                  >
                    <InsertDriveFileOutlined />
                    <Text sx={styles.text}>{file.name}</Text>
                  </Box>
                )}
                <Box sx={styles.fieldsText}>
                  <Text sx={styles.infoText}>{file.message}</Text>
                  {file.loading ? <CircularProgress size={24} color="inherit" /> : <></>}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="date"
            title="Data Inicial do Período"
            value={initialDate}
            onChange={(date) => setInitialDate(date)}
            maxDate={finalDate ? finalDate : new Date()}
            error=""
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="date"
            title="Data Final do Período"
            value={finalDate}
            onChange={(date) => setFinalDate(date)}
            minDate={initialDate ? initialDate : new Date("2016-01-01")}
            error=""
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ paddingBlock: 2 }}>
        {!files[0]?.fileXML && !files[0]?.fileXLSL && (
          <>
            <Grid size={{ xs: 12, sm: 6 }}>
              {xmlFile ? (
                <RButton
                  color="secondary"
                  sx={styles.showName.container}
                  onClick={() => setXmlFile(null)}
                >
                  <Text sx={styles.showName.text}>Eliminar {xmlFile.name}</Text>
                  <HighlightOffIcon />
                </RButton>
              ) : (
                <>
                  <input
                    type="file"
                    id="xml-file-input"
                    style={{ display: "none" }}
                    accept=".xml"
                    onChange={handleSIAFIChange}
                    disabled={xmlFile}
                  />
                  <label htmlFor="xml-file-input">
                    <RButton component="span" disabled={xmlFile}>
                      Carregar SIAFI Extração (xml)
                    </RButton>
                  </label>
                </>
              )}
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              {xlsxFile ? (
                <RButton
                  color="secondary"
                  sx={styles.showName.container}
                  onClick={() => setXlsxFile(null)}
                >
                  <Text sx={styles.showName.text}>Eliminar {xlsxFile.name}</Text>
                  <HighlightOffIcon />
                </RButton>
              ) : (
                <>
                  <input
                    type="file"
                    id="xlsx-file-input"
                    style={{ display: "none" }}
                    accept=".xlsx"
                    onChange={handleSIAFIChange}
                    disabled={xlsxFile}
                  />
                  <label htmlFor="xlsx-file-input">
                    <RButton component="span" disabled={xlsxFile}>
                      Carregar Ordens Bancárias (xlsx)
                    </RButton>
                  </label>
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>

      <Grid container spacing={2} sx={{ justifyContent: "right" }}>
        {(files[0]?.fileXML || files[0]?.fileXLSL) && (
          <Grid size={{ xs: 12, sm: 4 }}>
            <RButton color="orange" variant="outlined" onClick={handleCancel}>
              Nova Importação
            </RButton>
          </Grid>
        )}
        <Grid size={{ xs: 12, sm: 3 }}>
          <RButton variant="outlined" onClick={handleClose}>
            Fechar
          </RButton>
        </Grid>
        <Grid size={{ xs: 12, sm: 5 }}>
          <RButton disabled={importBtnDisabled} onClick={handleVerifyDates}>
            Importar Arquivos
          </RButton>
        </Grid>
      </Grid>
    </>
  );
}

export default SIAFI;

const styles = {
  infoText: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "15px",
    alignText: "center",
  },
  containerFields: {
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: 2,
  },
  fields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    flexDirection: "column",
    "@media (min-width: 600px)": {
      flexDirection: "row",
    },
  },
  fieldsText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  text: {
    maxWidth: "40ch" /* Tamanho */,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Montserrat, sans-serif",
    whiteSpace: "nowrap",
  },
  showName: {
    container: {
      display: "flex",
      alignItems: "center",
      gap: 1,
    },
    text: {
      maxWidth: "30ch" /* Tamanho */,
      fontFamily: "Montserrat, sans-serif",
      fontSize: 12,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
};
