import * as Yup from "yup";

export const statsFormValidationSchema = Yup.object().shape({
  searchType: Yup.string().required("Campo obrigatório"),
  companyId: Yup.string().required("Campo obrigatório"),
  initialDate: Yup.date()
    .typeError("Data inválida")
    .required("Campo obrigatório"),
  finalDate: Yup.date()
    .when(
      "initialDate",
      (dateStart, yup) =>
        dateStart &&
        yup.min(dateStart, "A data final não pode ser anterior à data de início")
    )
    .typeError("Data inválida")
    .required("Campo obrigatório"),
  userId: Yup.string().when("searchType", {
    is: "search",
    then: Yup.string().required("Campo obrigatório"),
  }),
  isDetail: Yup.bool().optional(),
});