import Report from "./report";

import {
  createCommonBody,
  heightHandler,
  tableStyle,
  cellStyle,
  getBackgroundTitle,
} from "./common";
var htmlToPdfmake = require("html-to-pdfmake");

const report = new Report();
const noBoders = [false, false, false, false];

const addItem = (item) => {
  let items = [];
  if (item !== null && item !== undefined) {
    items.push([
      {
        text: item.title,
        alignment: "center",
        color: "white",
        bold: true,
        fillColor: getBackgroundTitle(item.title),
        border: noBoders,
      },
    ]);
    item.content?.forEach((value) => {
      items.push([
        {
          columns: [
            {
              stack: [
                {
                  text: value.title.toUpperCase(),
                  style: ["title"],
                  fontSize: 14,
                  marginTop: 20,
                  bold: true,
                },
                {
                  text: "___________________________________________________________________________________________________",
                  marginTop: -10,
                  marginBottom: 5,
                },
                {
                  text: htmlToPdfmake(value.text),
                  color: "#555555",
                  marginBottom: 20,
                },
              ],
              unbreakable: true,
            },
          ],
          border: noBoders,
        },
      ]);
      return null;
    });
  }
  return items;
};

const createDetailBody = (data) => {
  let inss = addItem(data?.inss);
  const irrf = addItem(data?.irrf);
  const csll = addItem(data?.csll);
  const iss = addItem(data?.iss);
  let body = [...inss, ...irrf, ...csll, ...iss];
  return body;
};

const createContent = (data) => {
  const ParseObservation = data.observation;
  const lastIndex = ParseObservation.lastIndexOf('-'); 
  const observationData = ParseObservation.substring(lastIndex + 1).trim();
  const tmp = {
    serviceTypeDescription: `${data.serviceCode} - ${data.serviceDescription}`,
    observation: data.observation,
    lcCode: data.lcCode,
    lcDescription: data.lcDescription,
    observationWithoutNumbers: observationData,
    natureOfContractor: data.category?.name,
    contractTypeDescription: data.contractType,
    nationalSimple: data.isSimples ? "Sim" : "Não",
    number: data?.number,
    cityContractor: data.cityContractor,
    cityHire: data.cityHire,
    cityService: data.cityService,
  };
  const body = createCommonBody(tmp);
  const bodyDetail = createDetailBody(data);

  let content = [
    { text: "Pesquisa do GT-Fácil", margin: [0, 20], style: "reportTitle" },

    {
      layout: "#fff",
      table: {
        heights: [30, 10],
        widths: [175, "auto"],
        style: tableStyle,
        headerRows: 1,
        bodyStyles: cellStyle,
        body,
      },
    },
    {
      margin: [0, 30],
      table: {
        heights: heightHandler,
        style: { ...tableStyle },
        bodyStyles: cellStyle,
        body: bodyDetail,
      },
    },
  ];
  report.setContent(content);
};

const generateGtFacilReport = (data) => {
  createContent(data);
  report.generateReport();
};

export default generateGtFacilReport;
