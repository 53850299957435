import React from "react";
import {
  Inbox as InboxIcon,
  InsertDriveFile as FileIcon,
  Send as SendIcon,
  MailOutline as MailOutlineIcon,
} from "@mui/icons-material";

const renderIcon = (Icon, props) => <Icon {...props} />;

/** Items to be rendered as filter options for Email component */
const taxInformationIcons = [
  {
    id: 1,
    alias: "Caixa de Entrada",
    name: "inbox",
    icon: (props) => renderIcon(InboxIcon, props),
  },
  {
    id: 2,
    alias: "Finalizadas",
    name: "finished",
    icon: (props) => renderIcon(SendIcon, props),
  },
  {
    id: 3,
    alias: "Rascunhos (Respostas)",
    name: "drafts",
    icon: (props) => renderIcon(FileIcon, props),
  },
  {
    id: 4,
    alias: "Todas as IFs",
    name: "all",
    icon: (props) => renderIcon(MailOutlineIcon, props),
  },
];

export default taxInformationIcons;
