export const hasINSSInTheServiceOptions = {
  true: "true",
  false: "false",
  doubt: "doubt",
};

export const witchArticleIN_RFB_971_2009Options = {
  art117: "art117",
  art118: "art118",
};

export const aliquotRetentionOptions = [
  { label: "11%", value: 0.11 },
  { label: "3,5%", value: 0.035 },
];

export const additionalAliquotRetentionOptions = [
  { label: "2%", value: 0.02 },
  { label: "3%", value: 0.03 },
  { label: "4%", value: 0.04 },
];
