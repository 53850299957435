export default [
  "casas-bahia.jpg",
  "camuru.jpg",
  "casa-civil.jpg",
  "clubeatleticomineiro.jpg",
  "pandora.jpg",
  "codemig.jpg",
  "compesa.png",
  "dnit.jpg",
  "dpu.jpg",
  "fibra.jpg",
  "fiep.jpg",
  "fiern.jpg",
  "fieto.jpg",
  "fundacaohermonas.jpg",
  "ifect.jpg",
  "artconta.jpg",
  "ifsp.jpg",
  "jfsp.jpg",
  "mpba.jpg",
  "mprn.jpg",
  "mte.jpg",
  "pbgas.jpg",
  "portodevitoria.jpg",
  "saneago.png",
  "seba.jpg",
  "sebrae.jpg",
  "sefazpr.jpg",
  "segplan.jpg",
  "sesc.jpg",
  "sptrans.jpg",
  "tce.jpg",
  "tcsc.jpg",
  "trepr.jpg",
  "trern.jpg",
  "trtmg.jpg",
  "ufrgs.jpg",
];
