import { apiURL } from "../../usecases/api";
export class ApiReinfUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async sendEvent(data, event) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Reinf/${event}`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async getReinfConsult(data) {
    try {
      const queryParams = new URLSearchParams(data).toString();

      const response = await this.httpClient.request({
        url: `${apiURL}/Reinf/Consult?${queryParams}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getReinfProtocol(data) {
    try {
      const url = data
        ? `${apiURL}/ReinfProtocol?${new URLSearchParams(data).toString()}`
        : `${apiURL}/ReinfProtocol`;
      const response = await this.httpClient.request({
        url: url,
        method: "GET",
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getReinfProtocolById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ReinfProtocol/${id}`,
        method: "GET",
      });

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("API request failed with status: " + response.status);
      }
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  }

  async deleteReinfProtocol(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ReinfProtocol/${id}`,
        method: "DELETE",
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async verifyClosedPeriod(data, event) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Reinf/Verifyclosed/${event}?companyId=${data.companyId}&calculationDate=${data.calculationDate}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getProtocolHistory(date) {
    const params = new URLSearchParams(date).toString();
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ReinfProtocol/StatusHistory?${params}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async sendRetification(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Reinf/Rectification`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async generateReport(report, reportRequestParams) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Report/Reinf/${report}`,
        method: "POST",
        params: {...reportRequestParams},
        responseType: "blob",
      });
      const blob = new Blob([response.data], { type: "application/pdf" });
      return window.URL.createObjectURL(blob);
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }
}
