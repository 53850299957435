import React, { useContext, useEffect, useState } from "react";
import { ApiNfseUseCases } from "~/data/usecases/nfse/apiNfse";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import CollapseRenderLayout from "./RenderLayout/CollapseRenderLayout";
import {
  Stack,
  TablePagination,
  Collapse,
  IconButton,
  TableHead,
  TableBody,
  TableCell,
  Table,
  TableRow,
  Checkbox,
  Tooltip,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box } from "@mui/material";
import { formatNumberToRealString } from "../../../../Utils/utilsCurrencyvalue";
import { formatDateToBrazilian } from "../../../../Utils/utilsDate";
import RButton from "../../../../components/Common/Buttons/RButton";
import {
  validationR2010,
  validationR4020AB,
  validationR4020C,
  validationR4020D,
} from "./ValidationSchema/ValidationSchema";
import { formatCpfCnpj } from "../../../../Utils/utilsReinf";
import CircularProgress from "@mui/material/CircularProgress";
import { AddCircleOutline, Check, ReportProblem } from "@mui/icons-material";
import AddMoreNfse from "./AddMoreNfse/AddMoreNfse";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { EventSend } from "../../EventSend/EventSend";
import ModalContainer from "~/presentation/views/Reinf/components/ModalContainer/ModalContainer";
import { tableStyles } from "~/presentation/views/Reinf/components/Styles/TableStyles";

function SentTableDocs({
  protocol,
  validNfseData,
  status,
  certificateData,
  receiptNumber,
  refreshData,
  isEventClosed,
  filter,
  isHistory,
  automatedData,
  selecetedRowsData,
  setEventsData,
}) {
  const apiNfse = new ApiNfseUseCases(makeHttpClient());
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO
  const QSOAB = QSO === "A" || QSO === "B";
  const QSOC = QSO === "C";
  const QSOD = QSO === "D";

  const selectedEvent = filter?.event;

  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const [loading, setLoading] = useState(true);

  //DATA
  const [nfseDataStorage, setNfseDataStorage] = useState([]);
  const [nfseData, setNfseData] = useState([]);

  //state para saber quais notas tiveram alteração para avisar usuário
  const [changedNfse, setChangedNFse] = useState([]);

  //CAMPOS
  const [inputsDisabled, setInputsDisabled] = useState(true);
  const [errors, setErrors] = useState({});

  //ABERTURA E FECHO DE CADA FICHA (COLLAPSE)
  const [openCollapse, setOpenCollapse] = useState({});

  //PAGINAÇÃO
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //LINHAS SELECIONADAS
  const [selectedRows, setSelectedRows] = useState(automatedData || []);

  const handleChangeItem = (newItem, index, field, byPass) => {
    if (!byPass) {
      const id = nfseData[index].id;
      if (!changedNfse.includes(id)) {
        setChangedNFse((prev) => [...prev, id]);
      }
    }
    setNfseData((nfseData) => {
      let updatedItems = [...nfseData];
      updatedItems[index] = newItem;
      if (field) {
        clearError(`[${index}].${field}`);
      }
      return updatedItems;
    });
  };

  const handleChangeINSSitem = (newItem, parentIndex, nestedIndex, field, byPass) => {
    if (!byPass) {
      const id = nfseData[parentIndex].id;
      if (!changedNfse.includes(id)) {
        setChangedNFse((prev) => [...prev, id]);
      }
    }
    setNfseData((nfseData) => {
      let updatedItems = [...nfseData];
      updatedItems[parentIndex].itemsNFse[nestedIndex] = newItem;
      if (field) {
        clearError(`[${parentIndex}].itemsNFse[${nestedIndex}].${field}`);
      }
      return updatedItems;
    });
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  useEffect(() => {
    const fetchNotas = async (protocol) => {
      try {
        const response = await apiNfse.getNfseByProtocol(protocol);
        if (response) {
          setNfseData(response);
          //storage é para ao cancelar os valores dos campos voltarem ao estado de envio
          setNfseDataStorage(response);
        }

        if (automatedData && automatedData.length > 0) {
          setSelectedRows(automatedData);

          const mergedArray = [...response, ...automatedData].reduce((accumulator, currentItem) => {
            const existingItem = accumulator.find((item) => item.id === currentItem.id);
            if (!existingItem) {
              return accumulator.concat([currentItem]);
            }
            return accumulator;
          }, []);

          setNfseData(mergedArray);
        }
      } catch (error) {
        setFeedbackSnackbar({
          isOpen: true,
          message: "Erro no retorno dos dados, verifique sua conexão",
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchNotas(protocol);
  }, []);

  //Validação dos campos consoante o evento e qso
  const handleValidation = (data) => {
    openBackdrop(true, "Atualizando o evento");

    if (selectedEvent === "r2010") {
      validationR2010
        .validate(data, { abortEarly: false })
        .then(async () => {
          setErrors({});
          handleUpdateNotasEventos(data);
        })
        .catch((errors) => {
          setErrors(
            errors.inner.reduce((acc, error) => {
              acc[error.path] = error.message;
              return acc;
            }, {})
          );
          setFeedbackSnackbar({
            isOpen: true,
            message: "Erros nos campos, corrija para poder enviar!",
            type: "warning",
          });
          openBackdrop(false);
        });
    }

    if (selectedEvent === "r4020") {
      if (QSOAB) {
        validationR4020AB
          .validate(data, { abortEarly: false })
          .then(async () => {
            setErrors({});
            handleUpdateNotasEventos(data);
          })
          .catch((errors) => {
            setErrors(
              errors.inner.reduce((acc, error) => {
                acc[error.path] = error.message;
                return acc;
              }, {})
            );
            setFeedbackSnackbar({
              isOpen: true,
              message: "Erros nos campos, corrija para poder enviar!",
              type: "warning",
            });
            openBackdrop(false);
          });
      }

      if (QSOC) {
        validationR4020C
          .validate(data, { abortEarly: false })
          .then(async () => {
            setErrors({});
            handleUpdateNotasEventos(data);
          })
          .catch((errors) => {
            setErrors(
              errors.inner.reduce((acc, error) => {
                acc[error.path] = error.message;
                return acc;
              }, {})
            );
            setFeedbackSnackbar({
              isOpen: true,
              message: "Erros nos campos, corrija para poder enviar!",
              type: "warning",
            });
            openBackdrop(false);
          });
      }

      if (QSOD) {
        validationR4020D
          .validate(data, { abortEarly: false })
          .then(async () => {
            setErrors({});
            handleUpdateNotasEventos(data);
          })
          .catch((errors) => {
            setErrors(
              errors.inner.reduce((acc, error) => {
                acc[error.path] = error.message;
                return acc;
              }, {})
            );
            setFeedbackSnackbar({
              isOpen: true,
              message: "Erros nos campos, corrija para poder enviar!",
              type: "warning",
            });
            openBackdrop(false);
          });
      }
    }
  };

  const handleUpdateEventsData = (eventsList) => {
    return eventsList.map((event) => {
      if (event.protocol === protocol) {
        return {
          ...event,
          nfseData: [...selectedRows],
        };
      }
      return event;
    });
  };

  //update de todas as documentos do evento + re-envio
  const handleUpdateNotasEventos = async (data) => {
    openBackdrop(true, "Atualizando documentos");
    const promises = data.map((item) =>
      apiNfse
        .updateNfse(item)
        .then((updatedItem) => {
          return updatedItem;
        })
        .catch((error) => {
          throw error; // Rejeita a promessa para que o Promise.all possa capturar
        })
    );

    try {
      // Aguarda todas as promessas serem resolvidas ou rejeitadas
      await Promise.all(promises);
      if (selectedEvent === "r2010" || selectedEvent === "r4020") {
        selecetedRowsData((prev) => handleUpdateEventsData(prev));
        setEventsData((prev) => handleUpdateEventsData(prev));
      }

      setFeedbackSnackbar({
        isOpen: true,
        message: "Documentos atualizados com sucesso!",
        type: "success",
      });

      setInputsDisabled(true);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao atualizar documentos!",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  //Atualizar o state SelectedRows ao alterar o nfseData
  useEffect(() => {
    const updatedSelectedRows = selectedRows.map((selectedRow) => {
      const updatedRow = nfseData.find((row) => row.id === selectedRow.id);
      return updatedRow ? updatedRow : selectedRow;
    });
    setSelectedRows(updatedSelectedRows);
  }, [nfseData]);

  //"Botão CANCELAR --> tudo volta ao estado inicial"
  const handleCancel = () => {
    setNfseData(nfseDataStorage);
    setInputsDisabled(true);
    setSelectedRows(nfseData);
    setErrors({});
    setChangedNFse([]);
  };

  //Open/Close Collapse
  const handleOpenCollapse = (index, isOpen) => {
    setOpenCollapse((prev) => ({
      ...prev,
      [index]: !isOpen,
    }));
  };

  //Modal: Adicionar Docs. Fiscais
  const [openAddMoreNfse, setopenAddMoreNfse] = useState(false);
  const handleOpenAddMoreNfse = () => {
    setopenAddMoreNfse(true);
  };
  const handleCloseAddMoreNfse = () => {
    setopenAddMoreNfse(false);
  };

  //modal event Send
  const [openEventSendModal, setOpenEventSendModal] = useState(false);
  // ⇣⇣⇣ abrir e fechar modal de envio de documentos
  const handleCloseEventSendModal = () => {
    setOpenEventSendModal(false);
    refreshData();
    setInputsDisabled(true);
  };

  //Selecionar TODAS as documentos
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedRows(nfseData);
    } else {
      setSelectedRows([]);
    }
  };

  //Selecionar nota individual
  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, item]);
    } else {
      setSelectedRows((prev) => prev.filter((selectedItem) => selectedItem.id !== item.id));
    }
  };

  const renderIcon = (value) => {
    if (value) {
      return (
        <Tooltip title="Existem dados que ainda não foram salvos">
          <ReportProblem sx={{ color: "#DCAD23" }} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip>
          <Check color="disabled" />
        </Tooltip>
      );
    }
  };

  //para por no checkbox de cada linha
  const isSelected = (item) => selectedRows.some((i) => i.id === item.id);

  //para o checkbox "titulo" do head
  const numSelected = selectedRows.length;
  const rowCount = nfseData.length;

  //Lida com os documentos adicionados
  useEffect(() => {
    if (nfseData.length > nfseDataStorage.length) {
      setSelectedRows(nfseData);
    }
  }, [nfseData]);

  // Calcular os índices de início e fim com base na página e nas linhas por página
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData = nfseData.length > 0 && nfseData.slice(startIndex, endIndex);

  const headStyle = { ...tableStyles.headCell, padding: "12px" };

  return (
    <Box sx={{ padding: 3 }}>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={35} color="inherit" />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              marginBottom: 2,
            }}
          >
            {status === 2 && selectedEvent !== "r9000" && !isHistory && (
              <RButton
                disabled={!inputsDisabled || isEventClosed}
                sx={{
                  ...styles.Button,
                  backgroundColor: inputsDisabled ? "#1341A1" : "#888888",
                }}
                onClick={() => {
                  setInputsDisabled(false);
                }}
              >
                {inputsDisabled ? "Retificar Evento" : "Retificando..."}
              </RButton>
            )}
          </Box>
          <Box sx={tableStyles.container}>
            <Table>
              <TableHead>
                <TableRow>
                  {!inputsDisabled && <TableCell sx={headStyle}></TableCell>}
                  {!inputsDisabled && <TableCell sx={headStyle}></TableCell>}
                  <TableCell sx={{ ...headStyle, padding: 0 }}>
                    <Checkbox
                      color="default"
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={handleSelectAllClick}
                      disabled={inputsDisabled}
                    />
                  </TableCell>
                  <TableCell sx={headStyle}>Nº do Doc</TableCell>
                  <TableCell sx={headStyle}>CNPJ/CPF Contratado</TableCell>
                  <TableCell sx={headStyle}>
                    {selectedEvent === "r2010" ? "Data de Emissão" : "  Data de Pagamento"}
                  </TableCell>
                  <TableCell sx={headStyle}>Valor da Operação</TableCell>
                  <TableCell sx={headStyle}>
                    {selectedEvent === "r2010"
                      ? "Base INSS"
                      : QSOAB
                        ? "Base IRPJ"
                        : QSOC
                          ? "Base IR"
                          : "Ret. IR"}
                  </TableCell>
                  <TableCell sx={headStyle}>
                    {selectedEvent === "r2010"
                      ? "Ret. INSS"
                      : QSOAB
                        ? "Ret. Base"
                        : QSOC
                          ? "Ret. IR"
                          : "Ret. CSRF"}
                  </TableCell>
                  <TableCell sx={headStyle}>CNPJ/CPF Contratante</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentData.length > 0 ? (
                  currentData?.map((item, index) => {
                    const isOpen = openCollapse[index] || false;
                    const realIndex = startIndex + index;
                    const isRowSelected = isSelected(item);
                    //saber se a nota já teve alteração
                    const nfseChanged = changedNfse.includes(item.id);
                    //style para nos alteradas
                    const styledColor = "#FAE4A2"; //amarelo claro
                    const styledBorder = `2px solid #F3B80C`; //amarelo torrado (escuro)
                    const styledCell = {
                      fontFamily: "Montserrat ",
                      fontSize: 14,
                      padding: "6px",
                      color: "#000000",
                      textAlign: "center",
                      borderBlock: nfseChanged ? styledBorder : "none",
                    };
                    return (
                      <React.Fragment key={index}>
                        <TableRow
                          sx={{
                            cursor: "pointer",
                            backgroundColor: nfseChanged ? styledColor : "#D9D9D9",
                          }}
                        >
                          {!inputsDisabled && (
                            <TableCell
                              sx={{
                                ...styledCell,
                                borderLeft: nfseChanged ? styledBorder : "none",
                              }}
                            >
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleOpenCollapse(index, isOpen)}
                              >
                                {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                              </IconButton>
                            </TableCell>
                          )}
                          {!inputsDisabled && (
                            <TableCell sx={styledCell}>
                              <Box sx={{ paddingTop: "5px" }}>{renderIcon(nfseChanged)}</Box>
                            </TableCell>
                          )}
                          <TableCell sx={{ ...styledCell, padding: 0 }}>
                            <Checkbox
                              color="default"
                              onChange={(event) => handleCheckboxChange(event, item)}
                              checked={isRowSelected}
                              disabled={inputsDisabled}
                            />
                          </TableCell>
                          <TableCell sx={styledCell}>{item.nFseNumber}</TableCell>
                          <TableCell sx={styledCell}>
                            {formatCpfCnpj(item.businessPartnerCNPJ)}
                          </TableCell>
                          <TableCell sx={styledCell}>
                            {formatDateToBrazilian(
                              selectedEvent === "r2010" ? item.dateOfIssue : item.dateOfPayment
                            )}
                          </TableCell>
                          <TableCell sx={styledCell}>
                            {formatNumberToRealString(item.amountOperation)}
                          </TableCell>
                          <TableCell sx={styledCell}>
                            {formatNumberToRealString(
                              selectedEvent === "r2010"
                                ? item.bcINSS
                                : QSOAB
                                  ? item.bcIR
                                  : QSOC
                                    ? item.bcIR
                                    : item.amountIR
                            )}
                          </TableCell>
                          <TableCell sx={styledCell}>
                            {formatNumberToRealString(
                              selectedEvent === "r2010"
                                ? item.amountINSS
                                : QSOAB
                                  ? item.amountAggregate
                                  : QSOC
                                    ? item.amountIR
                                    : item.amountCSRF
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              ...styledCell,
                              borderRight: nfseChanged ? styledBorder : "none",
                            }}
                          >
                            {formatCpfCnpj(item.contractorCNPJ)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{ ...tableStyles.cellCollapse, padding: "2px " }}
                            colSpan="100%"
                          >
                            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                              <CollapseRenderLayout
                                item={item}
                                index={realIndex}
                                onChangeItem={handleChangeItem}
                                onChangeINSSitem={handleChangeINSSitem}
                                selectedEvent={selectedEvent}
                                nfseData={nfseData}
                                errors={errors}
                                disabled={inputsDisabled}
                                QSO={QSO}
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="100%" sx={tableStyles.cellError}>
                      <p style={{ textAlign: "center" }}>
                        Este evento não possuí documentos disponíveis.
                      </p>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
          {nfseData.length > 9 && (
            <Stack spacing={1} sx={tableStyles.pagination}>
              <TablePagination
                component="div"
                count={nfseData?.length || 0}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                labelRowsPerPage="Informações por página:"
                rowsPerPageOptions={[10, 15, 20]}
              />
            </Stack>
          )}
          {!inputsDisabled && (
            <>
              <Box sx={{ marginTop: 2 }}>
                <RButton
                  sx={{
                    ...styles.Button,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                  onClick={handleOpenAddMoreNfse}
                >
                  Adicionar Docs. Fiscais
                  <AddCircleOutline />
                </RButton>
                <ModalContainer
                  scroll={true}
                  open={openAddMoreNfse}
                  onClose={handleCloseAddMoreNfse}
                >
                  <AddMoreNfse
                    onClose={handleCloseAddMoreNfse}
                    protocol={protocol}
                    validNfse={validNfseData}
                    currentNfse={nfseData}
                    setData={setNfseData}
                    QSO={QSO}
                    selectedEvent={selectedEvent}
                  />
                </ModalContainer>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: 2,
                  marginTop: 2,
                }}
              >
                <>
                  <RButton sx={styles.Button} variant="outlined" onClick={handleCancel}>
                    CANCELAR ALTERAÇÃO
                  </RButton>
                  <Tooltip title="Para confirmar as alterações, selecione os documentos fiscais que deseja atualizar. Para retirar um documento do evento, não o selecione no ato da retificação.">
                    <span style={{ cursor: "not-allowed" }}>
                      <RButton
                        sx={styles.Button}
                        disabled={selectedRows.length === 0}
                        onClick={() => {
                          handleValidation(selectedRows);
                        }}
                      >
                        CONFIRMAR ALTERAÇÃO
                      </RButton>
                    </span>
                  </Tooltip>
                </>
              </Box>
            </>
          )}

          <ModalContainer open={openEventSendModal} onClose={handleCloseEventSendModal}>
            <EventSend
              data={selectedRows}
              filter={filter}
              certificateData={certificateData}
              closeModal={handleCloseEventSendModal}
              refreshData={refreshData}
              receiptNumber={receiptNumber}
              operation={2}
            />
          </ModalContainer>
        </>
      )}
    </Box>
  );
}

export default SentTableDocs;

const styles = {
  //MODAL:
  ConfirmationModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "fit-content",
    width: "100%",
    maxHeight: "80%",
    borderRadius: "18px",
    bgcolor: "#E8E8E8",
    boxShadow: 24,
    overflow: "auto",
    p: 4,
  },
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  },
  subTitle: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  ButtonBox: {
    position: "fixed",
    zIndex: 999,
    right: "10px",
    display: "flex",
    flexDirection: "column",
    gap: 1,
    bottom: "90px",
    background: "white",
    borderRadius: "10px",
    padding: 1,
    border: "1px solid blue",
  },
  Button: {
    width: "250px",
    borderRadius: "8px",
    boxShadow: 0,
  },
};
