import React from "react";
import {
  IconButton,
  Container,
} from "~/presentation/views/Admin/components/MessageListActions/styles";
import Checkbox from "~/presentation/components/Checkbox";
import { Delete as DeleteIcon, Refresh as RefreshIcon } from "@mui/icons-material";

/**
 * Actions to be shown on top of MessageList component.
 * Some very used icons and functions are refactored here.
 * Additional actions can be added by passing them to the
 * renderAfter prop.
 * In the future, it may be a good idea to add conditionals
 * and props to control default actions and icons. For instance,
 * no over-engineering needed.
 */

const MessageListActions = ({
  selectedItems,
  items,
  onChange,
  handleDelete,
  handleRefresh,
  renderAfter: RenderAfter,
  userIsSuperAdmin,
}) => {
  return (
    <Container>
      {/* unselect all button */}
      <Checkbox
        size="small"
        indeterminate={selectedItems.length > 0}
        checked={selectedItems.length > 0}
        onClick={() =>
          selectedItems.length > 0 ? onChange([]) : onChange(items.map((item) => item))
        }
      />

      {/* refresh button */}
      <IconButton onClick={handleRefresh}>
        <RefreshIcon fontSize="small" />
      </IconButton>

      {userIsSuperAdmin && (
        <>
          {" "}
          {/* delete button */}
          <IconButton
            disabled={!selectedItems.length}
            onClick={() => handleDelete(selectedItems.map(({ id }) => id))}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </>
      )}

      {RenderAfter && <RenderAfter />}
    </Container>
  );
};

export default MessageListActions;
