import React from "react";
import {
  Assessment,
  AssignmentInd,
  Business,
  Dashboard,
  FileCopy,
  Group,
  History,
  Send,
} from "@mui/icons-material";

const menus = [
  {
    id: 0,
    name: "Painel GT-Reinf",
    icon: <Dashboard />,
    link: "/reinf",
  },
  {
    id: 1,
    name: "Estabelecimento",
    icon: <Business />,
    link: "/reinf/estabelecimentos-r1000",
  },  
  {
    id: 2,
    name: "Certificado Digital",
    icon: <AssignmentInd />,
    link: "/reinf/certificado-digital",
  },
  {
    id: 3,
    name: "Parceiros de Negócio",
    icon: <Group />,
    link: "/reinf/parceiros-negocio",
  },
  {
    id: 4,
    name: "Documentos Fiscais",
    icon: <FileCopy />,
    link: "/reinf/painel-doc-fiscais",
  },
  {
    id: 5,
    name: "Enviar EFD-REINF",
    icon: <Send />,
    link: "/reinf/eventos-efd-reinf",
  },
  {
    id: 6,
    name: "Relatórios",
    icon: <Assessment />,
    link: "/reinf/relatorios",
  },
  {
    id: 7,
    name: "Histórico de Eventos",
    icon: <History />,
    link: "/reinf/historico-eventos",
  },
];
export default menus;
