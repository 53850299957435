import React, { useContext, useEffect, useState } from "react";
import {
  ArrowDownward,
  ArrowUpward,
  FileOpenOutlined,
  KeyboardArrowDown,
  KeyboardArrowRight,
  ReportProblem,
  Update,
} from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { formatCpfCnpj, getDocType } from "../Utils/utilsReinf";
import { formatDateToBrazilian } from "../Utils/utilsDate";
import { formatNumberToRealString } from "../Utils/utilsCurrencyvalue";
import RenderLayout from "../components/Layouts/RenderLayout";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { ApiServiceClassification } from "~/data/usecases/serviceClassification/apiServiceClassification";
import { ApiIncomeNature } from "~/data/usecases/incomeNature/incomeNature";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiBusinessPartnerUseCases } from "~/data/usecases/businessPartner/businessPartner";
import { tableStyles } from "../components/Styles/TableStyles";

function DocTable({
  data,
  selectedEvent,
  // tableType,
  refreshData,
  isEventClosed,
  selectedRows,
  setSelectedRows,
  handleChangeFieldValue,
  handleChangeINSSfield,
  errors,
  setErrors,
  page,
  setPage,
  setCurrentNfseData,
}) {
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO
  const R2010 = selectedEvent === "r2010";
  const R4020AB = selectedEvent === "4020" && (QSO === "A" || QSO === "B");
  const R4020C = selectedEvent === "4020" && QSO === "C";
  const R4020D = selectedEvent === "4020" && QSO === "D";

  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const apiServiceclass = new ApiServiceClassification(makeHttpClient());
  const apiIncomeNature = new ApiIncomeNature(makeHttpClient());
  const apiBusinessPartner = new ApiBusinessPartnerUseCases(makeHttpClient());
  //SELECT INPUT OPTIONS
  const [serviceClassificationOptions, setServiceClassificationOptions] = useState([]); //select Classificação Serviço
  const [incomeNatureOptions, setIncomeNatureOptions] = useState([]); //select Natureza Rendimento
  const [businessPartnerOptions, setBusinessPartnerOptions] = useState([]); //lista de contratados

  //ABERTURA E FECHO DE CADA FICHA (COLLAPSE)
  const [openCollapse, setOpenCollapse] = useState({});

  //para o checkbox "titulo" do head
  const dataAbleToSend = data.filter((item) => !item.hasEventSentWithSameCnpj);
  const numSelected = selectedRows.length;
  const rowCount = dataAbleToSend.length;

  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Calcular os índices de início e fim com base na página e nas linhas por página
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData = data.length > 0 && data.slice(startIndex, endIndex);

  //Ordenação
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState(""); // armazena a coluna clicada

  const handleSort = (field) => {
    if (field) {
      const isAsc = orderBy === field && orderDirection === "asc";
      setOrderDirection(isAsc ? "desc" : "asc");
      setOrderBy(field);
      const sortedData = [...data].sort((a, b) => {
        if (a[field] < b[field]) {
          return orderDirection === "asc" ? -1 : 1;
        }
        if (a[field] > b[field]) {
          return orderDirection === "asc" ? 1 : -1;
        }
        return 0;
      });
      setCurrentNfseData(sortedData); // Atualiza o estado com os dados ordenados
    }
  };

  //Open/Close Collapse
  const handleOpenCollapse = (index, isOpen) => {
    // Verificar se o click ocorreu dentro da célula do Checkbox
    const tagName = event.target.tagName.toLowerCase();
    if (tagName === "input") {
      return; // Impede a execução da lógica se o click foi no Checkbox
    }
    setOpenCollapse((prev) => ({
      ...prev,
      [index]: !isOpen,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setOpenCollapse({});
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Selecionar TODAS as documentos
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedRows(dataAbleToSend);
    } else {
      setSelectedRows([]);
    }
  };

  //Selecionar nota individual
  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, item]);
    } else {
      setSelectedRows((prev) => prev.filter((selectedItem) => selectedItem.id !== item.id));
    }
  };

  //para por no checkbox de cada linha
  const isSelected = (item) => selectedRows.some((i) => i.id === item.id);

  const renderFileIcon = (value, nfseChanged) => {
    if (value) {
      return (
        <>
          {nfseChanged && (
            <Tooltip title="Existem dados que ainda não foram salvos">
              <ReportProblem sx={{ color: "#DCAD23" }} />
            </Tooltip>
          )}
          <Tooltip title="Este documento não pode ser enviado, pois já existe um evento enviado com o mesmo CNPJ">
            <FileOpenOutlined color="action" />
          </Tooltip>
        </>
      );
    } else {
      return (
        <>
          {nfseChanged && (
            <Tooltip title="Existem dados que ainda não foram salvos">
              <ReportProblem sx={{ color: "#DCAD23" }} />
            </Tooltip>
          )}
          <Tooltip title="Doc. Fiscal ainda não foi enviado.">
            <Update color="action" />
          </Tooltip>
        </>
      );
    }
  };

  useEffect(() => {
    const fetchDataOptions = async () => {
      try {
        const serviceClassResponse = await apiServiceclass.listServiceClassification();
        const incomeNatureResponse = await apiIncomeNature.listIncomeNature();
        const businessPartnerResponse = await apiBusinessPartner.listBusinessPartner();

        if (serviceClassResponse) {
          if (Array.isArray(serviceClassResponse)) {
            setServiceClassificationOptions(serviceClassResponse);
          } else {
            setFeedbackSnackbar({
              isOpen: true,
              message: "Os dados de classificação de serviço não foram carregados",
              type: "error",
            });
          }
        }

        if (incomeNatureResponse) {
          if (Array.isArray(incomeNatureResponse)) {
            setIncomeNatureOptions(incomeNatureResponse);
          } else {
            setFeedbackSnackbar({
              isOpen: true,
              message: "Os dados de Natureza de Rend. não foram carregados",
              type: "error",
            });
          }
        }
        if (businessPartnerResponse) {
          if (Array.isArray(businessPartnerResponse)) {
            setBusinessPartnerOptions(businessPartnerResponse);
          } else {
            setFeedbackSnackbar({
              isOpen: true,
              message: "os dados do contratado não foram carregados",
              type: "error",
            });
          }
        }
      } catch (error) {
        setFeedbackSnackbar({
          isOpen: true,
          message: "Erro ao buscar as opções de seleção.",
          type: "error",
        });
      }
    };
    fetchDataOptions();
  }, []);

  const getColumns = () => {
    const columns = [
      { field: "nFseNumber", headerName: "NºDoc" },
      { field: "businessPartnerCNPJ", headerName: "CNPJ do Contratado" },
      { field: "businessPartnerName", headerName: "Nome do Contratado" },
      {
        field: R2010 ? "dateOfIssue" : "dateOfPayment",
        headerName: R2010 ? "Data de emissão" : "Data de pagamento",
      },
      { field: "amountOperation", headerName: "Valor da Operação" },
    ];

    if (R2010) {
      columns.push(
        { field: "bcINSS", headerName: "Base INSS" },
        { field: "amountINSS", headerName: "Ret. INSS" }
      );
    }

    if (R4020AB) {
      columns.push(
        { field: "bcIRPJ", headerName: "Base IRPJ" },
        { field: "amountAggregate", headerName: "Ret. Agregada" }
      );
    }

    if (R4020C) {
      columns.push(
        { field: "bcIR", headerName: "Base IR" },
        { field: "amountIR", headerName: "Ret. IR" }
      );
    }

    if (R4020D) {
      columns.push(
        { field: "bcIR", headerName: "Base IR" },
        { field: "amountIR", headerName: "Ret. IR" },
        { field: "bcCSRF", headerName: "Base CSRF" },
        { field: "amountCSRF", headerName: "Ret. CSRF" }
      );
    }

    columns.push(
      { field: "typeDoc", headerName: "Tipo de Doc." },
      { field: "contractorCNPJ", headerName: "CNPJ do Contratante" },
      { headerName: "Status", sortable: false }
    );

    return columns;
  };

  const headStyle = { ...tableStyles.headCell, padding: "10px" };

  return (
    <Box sx={{ ...tableStyles.container, margin: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={headStyle}></TableCell>
            <TableCell sx={{ ...headStyle, padding: 0 }}>
              <Checkbox
                color="default"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={handleSelectAllClick}
              />
            </TableCell>
            {getColumns().map((col, i) => (
              <TableCell
                sx={{
                  ...headStyle,
                  cursor: col.sortable === false ? "auto" : "pointer",
                }}
                key={i}
                onClick={() => {
                  if (col.sortable === false) return; //precisa ser === false
                  handleSort(col.field);
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {col.headerName}
                  {orderBy === col.field ? (
                    orderDirection === "asc" ? (
                      <ArrowUpward fontSize="small" />
                    ) : (
                      <ArrowDownward fontSize="small" />
                    )
                  ) : null}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {currentData.length > 0 ? (
            currentData?.map((item, index) => {
              //este realIndex assegura passar o index real do array data para o component
              //que será issencial para a função onChange dos campos
              //  !!! NÃO RETIRAR !!!
              const realIndex = startIndex + index;
              const isOpen = openCollapse[index] || false;
              const isRowSelected = isSelected(item);
              const disabledCheckBox = item.hasEventSentWithSameCnpj;
              //saber se a nota já teve alteração
              const nfseChanged = item?.hadChanges;
              //style para nos alteradas
              const styledColor = "#FAE4A2"; //amarelo claro
              const styledBorder = `2px solid #F3B80C`; //amarelo torrado (escuro)
              const styledCell = {
                fontFamily: "Montserrat ",
                fontSize: 14,
                padding: "6px",
                color: "#000000",
                textAlign: "center",
                borderBlock: nfseChanged ? styledBorder : "none",
              };
              return (
                <React.Fragment key={index}>
                  <TableRow
                    sx={{
                      cursor: "pointer",
                      backgroundColor: nfseChanged ? styledColor : "#D9D9D9",
                    }}
                    onClick={() => {
                      handleOpenCollapse(index, isOpen);
                    }}
                  >
                    <TableCell
                      sx={{ ...styledCell, borderLeft: nfseChanged ? styledBorder : "none" }}
                    >
                      <IconButton aria-label="expand row" size="small">
                        {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ ...styledCell, padding: 0 }}>
                      <Checkbox
                        color="default"
                        onChange={(event) => handleCheckboxChange(event, item)}
                        checked={isRowSelected}
                        disabled={disabledCheckBox}
                      />
                    </TableCell>
                    <TableCell sx={styledCell}>{item.nFseNumber}</TableCell>
                    <TableCell sx={styledCell}>{formatCpfCnpj(item.businessPartnerCNPJ)}</TableCell>
                    <TableCell sx={styledCell}>{item.businessPartnerName}</TableCell>

                    <TableCell sx={styledCell}>
                      {R2010
                        ? formatDateToBrazilian(item.dateOfIssue)
                        : item.dateOfPayment
                          ? formatDateToBrazilian(item.dateOfPayment)
                          : "-"}
                    </TableCell>
                    <TableCell sx={styledCell}>
                      {formatNumberToRealString(item.amountOperation)}
                    </TableCell>
                    {R2010 && (
                      <>
                        <TableCell sx={styledCell}>
                          {formatNumberToRealString(item.bcINSS)}
                        </TableCell>
                        <TableCell sx={styledCell}>
                          {formatNumberToRealString(item.amountINSS)}
                        </TableCell>
                      </>
                    )}
                    {R4020AB && (
                      <>
                        <TableCell sx={styledCell}>{formatNumberToRealString(item.bcIR)}</TableCell>
                        <TableCell sx={styledCell}>
                          {formatNumberToRealString(item.amountAggregate)}
                        </TableCell>
                      </>
                    )}
                    {R4020C && (
                      <>
                        <TableCell sx={styledCell}>{formatNumberToRealString(item.bcIR)}</TableCell>
                        <TableCell sx={styledCell}>
                          {formatNumberToRealString(item.amountIR)}
                        </TableCell>
                      </>
                    )}
                    {R4020D && (
                      <>
                        <TableCell sx={styledCell}>{formatNumberToRealString(item.bcIR)}</TableCell>
                        <TableCell sx={styledCell}>
                          {formatNumberToRealString(item.amountIR)}
                        </TableCell>
                        <TableCell sx={styledCell}>
                          {formatNumberToRealString(item.bcCSRF)}
                        </TableCell>
                        <TableCell sx={styledCell}>
                          {formatNumberToRealString(item.amountCSRF)}
                        </TableCell>
                      </>
                    )}
                    <TableCell sx={styledCell}>{getDocType(item.typeDoc)}</TableCell>
                    <TableCell sx={styledCell}>{formatCpfCnpj(item.contractorCNPJ)}</TableCell>
                    <TableCell
                      sx={{ ...styledCell, borderRight: nfseChanged ? styledBorder : "none" }}
                    >
                      <Box sx={{ paddingTop: "3px" }}>
                        {renderFileIcon(item.hasEventSentWithSameCnpj, nfseChanged)}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ ...tableStyles.cellCollapse, padding: "2px " }} colSpan="100%">
                      <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <RenderLayout
                          nfseData={item}
                          nfseIndex={realIndex}
                          selectedEvent={selectedEvent}
                          refreshData={() => {
                            setOpenCollapse({});
                            refreshData();
                          }}
                          isEventClosed={isEventClosed}
                          handleChangeFieldValue={handleChangeFieldValue}
                          handleChangeINSSfield={handleChangeINSSfield}
                          serviceClassificationOptions={serviceClassificationOptions}
                          incomeNatureOptions={incomeNatureOptions}
                          businessPartnerOptions={businessPartnerOptions}
                          errors={errors}
                          setErrors={setErrors}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })
          ) : (
            <TableRow>
              <TableCell sx={tableStyles.cellError} colSpan="100%">
                <p style={{ textAlign: "center" }}>Nenhuma informação encontrada...</p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {data.length > 9 && (
        <Stack spacing={1} sx={tableStyles.pagination}>
          <TablePagination
            component="div"
            count={data?.length || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Informações por página:"
            rowsPerPageOptions={[10, 15, 20]}
          />
        </Stack>
      )}
    </Box>
  );
}

export default DocTable;
