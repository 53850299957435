import { apiURL } from "../../usecases/api";
export class ApiDigitalCertUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  async registerDigitalCert(dataToSend) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/DigitalCert`,
        method: "POST",
        data: dataToSend,
        headers: {
          "Content-Type": "application/json",
        },
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getDigitalCerts() {
    try {
      const { companyId } = JSON.parse(localStorage.getItem("GT_user"));

      const response = await this.httpClient.request({
        url: `${apiURL}/DigitalCert`,
        method: "GET",
      });

      // Filtro de certs da companyId
      const filteredCerts = response.data.filter(cert => cert.companyId === companyId);

      return filteredCerts;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateDigitalCert(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/DigitalCert`,
        method: "PUT",
        data,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getDigitalCertById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/DigitalCert/${id}`,
        method: "GET",
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deleteDigitalCert(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/DigitalCert/${id}`,
        method: "DELETE",
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async downloadDigitalCert(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/DigitalCert/download/${id}`,
        method: "GET",
        responseType: 'blob',
      });
  
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
