import React from "react";
import { MoneyInput } from "~/presentation/components/MoneyInput";
import { QuestionLabel } from "~/presentation/views/GTFacil/Simulator/components/QuestionLabel";
import { QuestionContainer } from "~/presentation/views/GTFacil/Simulator/components/QuestionContainer";

export const MoneyQuestion = ({
  text,
  excerpt,
  value,
  onChange,
  allowNegative,
  disabled,
  prefix,
}) => {
  return (
    <QuestionContainer>
      <QuestionLabel text={text} excerpt={excerpt} />
      <MoneyInput
        value={value}
        onChange={onChange}
        disabled={disabled}
        allowNegative={allowNegative}
        prefix={prefix}
      />
    </QuestionContainer>
  );
};
