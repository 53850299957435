import React, { useState, useEffect } from "react";
import { Box, FormControlLabel, Checkbox, Tooltip } from "@mui/material";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { PersonAddAlt1 } from "@mui/icons-material";
import ModalContainer from "~/presentation/views/Reinf/components/ModalContainer/ModalContainer";
import { DependentModal } from "~/presentation/views/Reinf/components/Layouts/paymentLayout/sections/Dependent";
import { ApiDependentUseCases } from "~/data/usecases/dependent/apiDependent";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { DeductionModal } from "~/presentation/views/Reinf/components/Layouts/paymentLayout/sections/Deduction";
import { ApiDeductionUseCases } from "~/data/usecases/deductions/apiDeductions";
import { formatNumberToRealString } from "~/presentation/views/Reinf/Utils/utilsCurrencyvalue";
import { PartnerForm } from "~/presentation/views/Reinf/BusinessPartner/PartnerForm/PartnerForm";
import Grid from "@mui/material/Grid2";
import { Tab, TabPanel, Tabs, TabsList } from "~/presentation/components/Tabs/Tabs";
import TableActions from "~/presentation/components/TableActions";
import Table from "~/presentation/views/Reinf/components/Table/Table";
import {
  useDeductionsOptions,
  useDependentsOptions,
} from "~/presentation/views/Reinf/Utils/utilsOptionsReinf";

export const Payments = ({ payData, setPayData, partners, incomeNatureOptions, handleSubmit }) => {
  const [open, setOpen] = useState(false);
  const [dedOpen, setDedOpen] = useState(false);
  const [depOpen, setDepOpen] = useState(false); //estado de valores selecionaveis
  const apiDependent = new ApiDependentUseCases(makeHttpClient());
  const apiDeduction = new ApiDeductionUseCases(makeHttpClient());
  const { getDescriptionDependent } = useDependentsOptions();
  const { getDescriptionDeduction } = useDeductionsOptions();

  //dependents get options
  const [dependents, setDependents] = useState([]);
  const columnsDependents = [
    {
      field: "cpf",
      headerName: "CPF",
      align: "center",
      renderCell: ({ value }) => formatCpfCnpj(value),
    },
    {
      field: "dependentType",
      headerName: "Tipo de dependente",
      align: "center",
      renderCell: ({ value }) => {
        return getDescriptionDependent(value);
      },
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: ({ row }) => (
        <TableActions
          handleEdit={() => handleEditDependent(row)}
          handleDelete={() => HandleDeleteDependent(row?.id)}
        />
      ),
    },
  ];
  const [deductions, setDeductions] = useState([]);
  const columnsDeductions = [
    {
      field: "type",
      headerName: "Tipo de dedução",
      align: "center",
      renderCell: ({ value }) => {
        return getDescriptionDeduction(value);
      },
    },
    {
      field: "amount",
      headerName: "Valor",
      align: "center",
      renderCell: ({ value }) => formatNumberToRealString(value),
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: ({ row }) => (
        <TableActions
          handleEdit={() => handleEditDeduction(row)}
          handleDelete={() => handleDeleteDeduction(row?.id)}
        />
      ),
    },
  ];

  const [selectedDeduction, setSelectedDeduction] = useState(null); // Estado para armazenar a dedução a ser editada
  const [selectedDependent, setSelectedDependent] = useState(null);

  const getDependents = async () => {
    const result = await apiDependent.getDependent(payData?.businessPartnerId);
    setDependents(result);
  };

  const getDeductions = async () => {
    const result = await apiDeduction.getDeduction(payData?.id);
    setDeductions(result);
  };

  const HandleDeleteDependent = async (id) => {
    await apiDependent.deleteDependent(id);
    getDependents();
  };

  const handleDeleteDeduction = async (id) => {
    await apiDeduction.deleteDeduction(id);
    getDeductions();
  };

  const handleEditDeduction = (deduction) => {
    setSelectedDeduction(deduction); //recebe objeto clicado para edição de deduction
    setDedOpen(true);
  };

  const handleEditDependent = (dependent) => {
    setSelectedDependent(dependent);
    setDepOpen(true);
  };

  //funtions utils
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  //funtions utils
  const handleOpenDedModal = () => setDedOpen(true);
  const handleCloseDedModal = () => {
    setDedOpen(false);
    setSelectedDeduction(null);
    getDeductions();
  };

  const handleOpenDepModal = () => setDepOpen(true);
  const handleCloseDepModal = () => {
    setDepOpen(false);
    setSelectedDependent(null);
    getDependents();
  };

  const cpfPartnersFilter = partners.filter((partners) => partners.personType === 0);

  const fciScpOptions = [
    { id: 0, label: "Não se aplica" },
    { id: 1, label: "FCI - Fundo ou clube de investimento" },
    { id: 2, label: "SCP - Sociedade em conta de participação" },
  ];

  //changes values forms
  const handleDateChange = (name, date) => {
    setPayData((prev) => ({
      ...prev,
      [name]: date ? new Date(date) : "",
    }));
  };

  const handleChange = (name, value) => {
    setPayData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (payData?.businessPartnerId) {
      getDeductions();
      getDependents();
    }
  }, [payData?.businessPartnerId]);

  return (
    <>
      <Box sx={{ backgroundColor: "#E6E6E6", borderRadius: 2 }}>
        <Grid container spacing={2} sx={{ paddingInline: 5, paddingBlock: 2 }}>
          <Grid size={{ xs: 12, sm: 2 }}>
            <Input
              title="Período de apuração"
              type="date"
              value={payData.dateRegister || ""}
              onChange={(date) => handleDateChange("dateRegister", date)}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Input
              type="select"
              title="Beneficiário"
              options={cpfPartnersFilter}
              getOptionLabel={(option) => `${formatCpfCnpj(option.cpfCnpj)} - ${option.legalName}`}
              placeholder="Selecionar contratado"
              value={
                cpfPartnersFilter.find((item) => item.id === payData.businessPartnerId) || null
              }
              onChange={(value) => handleChange("businessPartnerId", value?.id)}
              icon={{
                title: "Adicionar beneficiário",
                func: handleOpen,
                component: <PersonAddAlt1 sx={{ color: "#fff" }} />,
              }}
              error={!payData?.businessPartnerId && "Campo Obrigatório"}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input
              type="select"
              title="Natureza do Rendimento"
              options={incomeNatureOptions}
              getOptionLabel={(option) => `${option.incomeCode} - ${option.incomeDescription}`}
              placeholder="Selecione a natureza do rendimento"
              value={incomeNatureOptions.find((item) => item.id === payData.incomeNatureId) || null}
              onChange={(value) => handleChange("incomeNatureId", value?.id)}
              error={!payData?.incomeNatureId && "Campo Obrigatório"}
            />
          </Grid>
          <Grid size={{ xs: 4, sm: 2 }}>
            <Input
              title="Data do fato gerador"
              type="date"
              value={payData.datePayment || ""}
              onChange={(date) => handleDateChange("datePayment", date)}
            />
          </Grid>
          <Tooltip title="Competência da folha de pagamento dos rendimentos decorrentes do trabalho">
            <Grid size={{ xs: 4, sm: 2 }}>
              <Input
                title="Competência"
                type="date"
                viewType="month-year"
                value={payData.competence || ""}
                onChange={(date) => handleDateChange("competence", date)}
              />
            </Grid>
          </Tooltip>
          <Grid size={2}>
            <Input
              type="amount"
              title="Valor do rendimento bruto"
              name="totalAmount"
              value={payData.totalAmount || ""}
              onChange={(e) => handleChange("totalAmount", e)}
              error={
                payData?.totalAmount < payData?.taxedAmount
                  ? "Se informado, deve ser maior ou igual ao valor Rend. Tributável."
                  : null
              }
            />
          </Grid>
          <Grid size={2}>
            <Input
              type="amount"
              title="Valor do rendimento tributável"
              name="taxedAmount"
              value={payData.taxedAmount || ""}
              onChange={(e) => handleChange("taxedAmount", e)}
            />
          </Grid>
          <Grid size={2}>
            <Input
              type="amount"
              title="Valor do IRRF"
              name="amountIR"
              value={payData.amountIR || ""}
              onChange={(e) => handleChange("amountIR", e)}
            />
          </Grid>
          <Grid size={2}>
            <Input
              type="text"
              title="Código do País"
              name="countryCode"
              value={payData.countryCode || ""}
              onChange={(e) => handleChange("countryCode", e.target.value)}
            />
          </Grid>
          {/* FCI / SCP */}
          <Grid size={{ xs: 2, md: 4 }}>
            <Input
              type="select"
              title="Indicativo de FCI/SCP"
              options={fciScpOptions}
              placeholder="Indicativo de FCI/SCP"
              value={fciScpOptions.find((item) => item.id === payData.fciScp) || fciScpOptions[0]}
              getOptionLabel={(option) => option.label}
              onChange={(value) => handleChange("fciScp", value.id)}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 2 }}>
            {payData?.fciScp != 0 && (
              <Input
                type="mask"
                mask={"00.000.000/0000-00"}
                title="CNPJ FciScp"
                name="cnpjFciScp"
                value={payData.cnpjFciScp || ""}
                onChange={(e) => handleChange("cnpjFciScp", e)}
              />
            )}
          </Grid>
          <Grid size={{ xs: 12, sm: 2 }}>
            {payData?.fciScp === 2 && (
              <Input
                type="amount"
                prefix="%"
                title="Porcentagem SCP"
                name="percentSCP"
                value={payData.percentSCP || ""}
                onChange={(e) => handleChange("percentSCP", e)}
              />
            )}
          </Grid>
          <Grid size={{ xs: 12, sm: 2 }}></Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={payData?.isRRA || false}
                  onChange={(e) => handleChange("isRRA", e.target.checked)}
                />
              }
              label="Rendimento Recebido Acumuladamente - RRA"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={payData?.isJudicialDecision || false}
                  onChange={(e) => handleChange("isJudicialDecision", e.target.checked)}
                />
              }
              label="Pagamento oriundo de decisão judicial"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={payData?.is13 || false}
                  onChange={(e) => handleChange("is13", e.target.checked)}
                />
              }
              label="Rendimento relativo a 13º salário ?"
            />
          </Grid>
          <Grid size={8}>
            <Input
              title="Observação sobre o pagamento"
              name="observation"
              type="textarea"
              minRows={5}
              maxlength="500"
              value={payData.observation || ""}
              onChange={(e) => handleChange("observation", e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>
      <Tabs defaultValue={1}>
        <TabsList sx={{ backgroundColor: "#fff", marginTop: 2, padding: 0 }}>
          <Tab value={1} sx={{ selectedBg: "#1341A1" }}>
            DEPENDENTES
          </Tab>
          <Tab value={2} sx={{ selectedBg: "#1341A1", display: "none" }} disabled>
            DEDUÇÕES
          </Tab>
        </TabsList>
        <TabPanel value={1}>
          {/* //dependentes */}
          <Table
            data={dependents}
            columns={columnsDependents}
            titleTable={"Dependentes"}
            handleAdd={handleOpenDepModal}
            searchInput={false}
            rowsPage={3}
          />
        </TabPanel>
        <TabPanel value={2}>
          <Table
            data={deductions}
            columns={columnsDeductions}
            titleTable={"Deduções"}
            handleAdd={handleOpenDedModal}
            searchInput={false}
            rowsPage={3}
          />
        </TabPanel>
      </Tabs>

      <Box sx={{ display: "flex", justifyContent: "end", gap: 1, p: 4 }}>
        <RButton sx={{ width: "150px" }} variant="outlined">
          Cancelar
        </RButton>
        <RButton
          sx={{ width: "150px" }}
          onClick={handleSubmit}
          disabled={!payData?.businessPartnerId || !payData?.incomeNatureId}
        >
          Salvar
        </RButton>
      </Box>
      <ModalContainer scroll={true} open={open} onClose={handleClose}>
        <PartnerForm personType={0} onClose={handleClose} />
      </ModalContainer>
      <ModalContainer scroll={false} open={depOpen} onClose={handleCloseDepModal}>
        <DependentModal
          payData={payData}
          initialValue={selectedDependent}
          handleClose={handleCloseDepModal}
        />
      </ModalContainer>
      <ModalContainer scroll={false} open={dedOpen} onClose={handleCloseDedModal}>
        <DeductionModal
          payData={payData}
          initialValue={selectedDeduction} //se edição
          handleClose={handleCloseDedModal}
          dependents={dependents}
        />
      </ModalContainer>
    </>
  );
};
