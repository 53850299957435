import { useState } from "react";

export const useValuesChanged = () => {
  const [values, setValues] = useState();

  const isValuesChanged = (newValues) => {
    return JSON.stringify(newValues) === JSON.stringify(values);
  };

  return {
    setValues,
    isValuesChanged,
  };
};
