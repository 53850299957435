import React from "react";

const DialogIcon = ({width = "64", height = "64"}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.0001 4.44446C16.3201 4.44446 3.55566 15.6089 3.55566 29.3333C3.55566 43.0578 16.3201 54.2222 32.0001 54.2222C34.9337 54.2188 37.8529 53.8121 40.6757 53.0133L50.5068 59.2711C50.7752 59.4433 51.0849 59.5402 51.4036 59.5515C51.7223 59.5628 52.0381 59.4882 52.3181 59.3355C52.598 59.1828 52.8317 58.9577 52.9947 58.6836C53.1577 58.4095 53.244 58.0967 53.2446 57.7778V45.8133C55.469 43.6794 57.2441 41.1221 58.4653 38.2919C59.6866 35.4616 60.3293 32.4157 60.3557 29.3333C60.4446 15.6089 47.6801 4.44446 32.0001 4.44446ZM50.2934 43.7511C50.1147 43.9169 49.972 44.1176 49.8741 44.3409C49.7762 44.5641 49.7253 44.8051 49.7246 45.0489V54.5422L41.9379 49.5822C41.7207 49.4449 41.4763 49.3564 41.2216 49.3226C40.9668 49.2888 40.7078 49.3107 40.4623 49.3867C37.7223 50.2377 34.8693 50.6692 32.0001 50.6667C18.2757 50.6667 7.11122 41.1022 7.11122 29.3333C7.11122 17.5645 18.2757 8.00001 32.0001 8.00001C45.7246 8.00001 56.889 17.5645 56.889 29.3333C56.8485 32.0624 56.2413 34.7532 55.1059 37.2351C53.9705 39.7171 52.3317 41.936 50.2934 43.7511Z"
        fill="white"
      />
      <path
        d="M44.4445 27.5555H19.5556C19.0841 27.5555 18.6319 27.7428 18.2985 28.0762C17.9651 28.4096 17.7778 28.8618 17.7778 29.3333C17.7778 29.8048 17.9651 30.257 18.2985 30.5904C18.6319 30.9238 19.0841 31.1111 19.5556 31.1111H44.4445C44.916 31.1111 45.3682 30.9238 45.7016 30.5904C46.035 30.257 46.2223 29.8048 46.2223 29.3333C46.2223 28.8618 46.035 28.4096 45.7016 28.0762C45.3682 27.7428 44.916 27.5555 44.4445 27.5555Z"
        fill="white"
      />
      <path
        d="M38.6668 36.4445H25.3334C24.8619 36.4445 24.4098 36.6318 24.0764 36.9652C23.743 37.2986 23.5557 37.7507 23.5557 38.2222C23.5557 38.6937 23.743 39.1459 24.0764 39.4793C24.4098 39.8127 24.8619 40 25.3334 40H38.6668C39.1383 40 39.5905 39.8127 39.9239 39.4793C40.2573 39.1459 40.4446 38.6937 40.4446 38.2222C40.4446 37.7507 40.2573 37.2986 39.9239 36.9652C39.5905 36.6318 39.1383 36.4445 38.6668 36.4445Z"
        fill="white"
      />
      <path
        d="M20.0532 22.2222H43.9465C44.418 22.2222 44.8702 22.0349 45.2036 21.7015C45.537 21.3681 45.7243 20.916 45.7243 20.4445C45.7243 19.973 45.537 19.5208 45.2036 19.1874C44.8702 18.854 44.418 18.6667 43.9465 18.6667H20.0532C19.5817 18.6667 19.1295 18.854 18.7961 19.1874C18.4627 19.5208 18.2754 19.973 18.2754 20.4445C18.2754 20.916 18.4627 21.3681 18.7961 21.7015C19.1295 22.0349 19.5817 22.2222 20.0532 22.2222Z"
        fill="white"
      />
    </svg>
  );
};

export default DialogIcon;
