import React, { createContext, useMemo, useState } from 'react'
import FeedbackSnackbar from '~/presentation/components/FeedbackSnackbar';

export const SnackbarContext = createContext()

export const SnackbarProvider = ({children}) => {

    const [snackbar, setSnackbar] = useState({
        isOpen: false, //sempre enviamos como {isOpen: true} para chamar.
        message: "Message!", //mensagem de texto
        type: "warning", //info, warning, error, success
      });

      const openSnackbar = ({ message, type, isOpen }) => {
        setSnackbar({ isOpen, message, type });
      };
    
      const closeSnackbar = () => {
        setSnackbar({ ...snackbar, isOpen: false });
      };
      
    const value={ setFeedbackSnackbar: openSnackbar, closeSnackbar }

return (
    <SnackbarContext.Provider value={useMemo(() => value, [])}>
      {children}
        <FeedbackSnackbar  
            isOpen={snackbar.isOpen}
            message={snackbar.message}
            type={snackbar.type}
            onClose={closeSnackbar}
        />
    </SnackbarContext.Provider>

  )
}
