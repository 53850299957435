import React from "react";
import { MoneyInput } from "~/presentation/components/MoneyInput";
import { QuestionLabel } from "~/presentation/views/GTFacil/Simulator/components/QuestionLabel";
import { QuestionContainer } from "~/presentation/views/GTFacil/Simulator/components/QuestionContainer";
import FormikCustomErrorMessage from "~/presentation/components/ErrorMessage/index.jsx";

export const PercentageQuestion = ({
  text,
  excerpt,
  value,
  onChange,
  allowNegative,
  disabled,
  name,
}) => {
  return (
    <QuestionContainer>
      <QuestionLabel text={text} excerpt={excerpt} />
      <MoneyInput
        value={value}
        onChange={onChange}
        disabled={disabled}
        allowNegative={allowNegative}
        prefix="%"
      />
      {name && <FormikCustomErrorMessage name={name} />}
    </QuestionContainer>
  );
};
