import { apiURL } from "../../usecases/api";
export class ApiSurveyUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async create(data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/Survey`,
      method: "POST",
      data,
    });
    return response.data;
  }

  async getAll() {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Survey`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Survey/${id}`,
        method: "GET",
      });
      let survey = response.data;
      survey.publishDate = new Date(response.data.publishDate);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async update(data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/Survey`,
      method: "PUT",
      data,
    });
    return response.data;
  }

  async delete(id) {
    const response = await this.httpClient.request({
      url: `${apiURL}/Survey/${id}`,
      method: "DELETE",
    });
    return response.data;
  }
}
