import { formatDateToBrazilian } from "../views/Reinf/Utils/utilsDate";

export function useTableFilters() {
  //ncessário no handleSearch
  const normalizeText = (text) => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9\s]/g, "")
      .toUpperCase();
  };

  //pega em qualquer data e procura lá o value, o setData é a info mostrada ao usuário
  const handleSearchTable = (value, data, setData) => {
    //normaliza o value enviado no input pelo usuário
    const normalizedValue = normalizeText(value);

    const filtered = data.filter((item) => {
      for (let key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          let fieldValue = item[key];

          //se é uma data queremos que ela fique "yyyy-mm-dd"
          if (key.includes("Date")) {
            fieldValue = formatDateToBrazilian(fieldValue);
          }

          // Normalizar o valor de cada campo do data
          const normalizedFieldValue = normalizeText(String(fieldValue));

          // Verificar se o valor de pesquisa está presente no campo normalizado
          if (normalizedFieldValue.includes(normalizedValue)) {
            return true;
          }
        }
      }
      return false;
    });

    //adiciona no setData as notas onde campos foram true
    setData(filtered);
  };

  return {
    handleSearchTable,
  };
}
