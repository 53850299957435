import { useState, useEffect } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiIncomeExemptionUseCases } from "~/data/usecases/IncomeExemption/incomeExemption";
import { ApiDependentUseCases } from "~/data/usecases/dependent/apiDependent";
import { ApiDeductionUseCases } from "~/data/usecases/deductions/apiDeductions";

//tipos de isenção de pagamentos
export const useIncomeExOptions = () => {
  const apiIncomeExemption = new ApiIncomeExemptionUseCases(makeHttpClient());
  const [incomeExOptions, setIncomeExOptions] = useState([]);

  useEffect(() => {
    const getIncomeExOptions = async () => {
      try {
        const result = await apiIncomeExemption.getIncomeExemptionTypes();
        setIncomeExOptions(result);
      } catch (error) {
        console.error("erro ao carregar opções de Isenção", error);
      }
    };

    getIncomeExOptions();
  }, []);

  return incomeExOptions;
};

//Tipo de deduções
export const useDeductionsOptions = () => {
  const apiDeductions = new ApiDeductionUseCases(makeHttpClient());
  const [deductionOptions, setDeductionOptions] = useState([]);

  useEffect(() => {
    const getDeductionOptions = async () => {
      try {
        const result = await apiDeductions.getDeductionTypes();
        setDeductionOptions(result);
      } catch (error) {
        console.error("Erro ao carregar Tipo de dedução", error);
      }
    };

    getDeductionOptions();
  }, []);

  const getDescriptionDeduction = (value) => {
    const option = deductionOptions.find((opt) => opt.code === value);
    return option?.description;
  };

  return { deductionOptions, getDescriptionDeduction };
};
//tipo de dependentes
export const useDependentsOptions = () => {
  const apiDependents = new ApiDependentUseCases(makeHttpClient());
  const [dependentsOptions, setDependentsOptions] = useState([]);

  useEffect(() => {
    const getDependentsOptions = async () => {
      try {
        const result = await apiDependents.getDependentTypes();
        setDependentsOptions(result);
      } catch (error) {
        console.error("Erro ao carregar Tipo dependentes", error);
      }
    };

    getDependentsOptions();
  }, []);

  const getDescriptionDependent = (value) => {
    const option = dependentsOptions.find((opt) => opt.code === value);
    return option?.description;
  };

  return { dependentsOptions, getDescriptionDependent };
};

/// Encontrar a opção de natureza de rendimento correspondente
export const useIncomeNatureOption = (incomeNatureOptions, incomeNatureId) => {
  return incomeNatureOptions.find((option) => option.id === incomeNatureId);
};
