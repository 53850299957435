export class ISSCalculator {
  constructor({ simulatorValue, exclusionValue, retentionAliquotPercentage }) {
    this.simulatorValue = simulatorValue;
    this.exclusionValue = exclusionValue;
    this.retentionAliquotPercentage = retentionAliquotPercentage;
  }

  get baseRetentionCalcValue() {
    return this.simulatorValue - this.exclusionValue;
  }

  get issRetentionValue() {
    return (
      this.baseRetentionCalcValue * (this.retentionAliquotPercentage / 100)
    );
  }
}
