import React, { useState } from "react";
import { Subtitle, Title } from "../../../styles";
import { Box } from "@mui/material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import { KeyboardArrowDown } from "@mui/icons-material";
import { formStyles } from "../../../../Styles/FormStyles";
import Grid from "@mui/material/Grid2";

function IRPJ({
  values,
  incomeNatureOptions,
  errors,
  inputDisabled,
  modalCalculateRetention,
  handleChangeFieldValue,
}) {
  const [show, setShow] = useState(false);

  //se é nota de produto NFe
  const isNFe = values?.typeDoc === 1;

  //FÓRMULA: Retenções
  const amountIRPJpercentage =
    values?.amountOperation > 0
      ? ((values?.amountIR / values?.amountOperation) * 100)?.toFixed(2)
      : "0.00";
  const amountCSLLpercentage =
    values?.amountOperation > 0
      ? ((values?.amountCSLL / values?.amountOperation) * 100)?.toFixed(2)
      : "0.00";
  const amountPISpercentage =
    values?.amountOperation > 0
      ? ((values?.amountPIS / values?.amountOperation) * 100)?.toFixed(2)
      : "0.00";
  const amountCONFINSpercentage =
    values?.amountOperation > 0
      ? ((values?.amountCOFINS / values?.amountOperation) * 100)?.toFixed(2)
      : "0.00";

  //ERROR: aliquotIR
  const possibleAliquots = ["0.00", "5.85", "4.89", "1.24", "2.20", "3.40", "4.65", "7.05", "9.45"];
  const isAliquotValid = possibleAliquots?.includes(values?.aliquotIR?.toFixed(2));

  const isAliquotWrong =
    values?.amountAggregate > 0 && values?.amountOperation > 0 && !isAliquotValid;
  const isAliquotWrongText = isAliquotWrong && "Confirme o valor da retenção";

  //ERROR: amountAggregate não pode ser maior do que a base
  const isAmountAggregateWrong = values?.amountAggregate > values?.amountOperation;
  const isAmountAggregateWrongText =
    isAmountAggregateWrong && "A Retenção não pode ser maior do que a Base!";

  //ERROR: data no futuro
  const isDateInTheFuture = values?.dateOfPayment > new Date();
  const isDateWrong = isDateInTheFuture && "Escolha uma data atual";

  const isAmountAggregatePositive = values.amountAggregate > 0;

  return (
    <Box sx={styles.container}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Title>Retenção do IRPJ e Cont. Sociais</Title>
        {isNFe && modalCalculateRetention}
      </Box>

      <Box sx={formStyles.fieldsWrapper}>
        <Grid container spacing={1}>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="select"
              title="Natureza do Rendimento"
              options={incomeNatureOptions}
              getOptionLabel={(option) => `${option.incomeCode} - ${option.incomeDescription}`}
              placeholder="Selecione a natureza do rendimento"
              value={incomeNatureOptions?.find((item) => item.id == values?.incomeNatureId)}
              onChange={(option) => {
                const { id, incomeDescription } = option;
                handleChangeFieldValue({
                  newItem: { incomeNatureId: id, incomeNatureDescription: incomeDescription },
                  field: "incomeNatureId",
                });
              }}
              error={errors[`[${values?.id}].incomeNatureId`]}
              disabled={inputDisabled === "R4020AB"}
            />
          </Grid>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="date"
              title="Data do Crédito/Pagamento"
              value={values?.dateOfPayment}
              onChange={(date) => {
                handleChangeFieldValue({
                  newItem: { dateOfPayment: date },
                  field: "dateOfPayment",
                });
              }}
              error={errors[`[${values?.id}].dateOfPayment`] || isDateWrong}
              disabled={inputDisabled === "R4020D"}
            />
          </Grid>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input type="amount" title="Valor da Base Agregada" value={values?.amountOperation} disabled />
          </Grid>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="amount"
              title="Valor da Retenção Agregada"
              value={values?.amountAggregate}
              onChange={(value) => {
                handleChangeFieldValue({
                  newItem: { amountAggregate: value },
                  field: "amountAggregate",
                });
              }}
              error={errors[`[${values?.id}].amountAggregate`] || isAmountAggregateWrongText}
              disabled={inputDisabled === "R4020AB"}
            />
          </Grid>
          {isAmountAggregatePositive && (
            <Grid size={{ xs: 4, lg: 3 }}>
              <Input
                type="amount"
                prefix="%"
                title="Alíquota"
                value={values?.aliquotIR.toFixed(2)}
                warning={isAliquotWrongText}
                disabled
              />
            </Grid>
          )}
        </Grid>

        {!isAmountAggregatePositive && (
          <Box sx={{ marginTop: 2 }}>
            <Subtitle
              onClick={() => {
                setShow(!show);
              }}
            >
              <KeyboardArrowDown
                style={{
                  fontSize: "2.4rem",
                  rotate: show ? "0deg" : "-90deg",
                  transition: "200ms",
                  color: "#A7A7A7",
                  marginBlock: 2,
                }}
              />
              Valores de IRPJ, CSLL, PIS/Pasep e COFINS
            </Subtitle>

            {show && (
              <Grid container spacing={1}>
                <Grid size={{ xs: 4, lg: 3 }}>
                  <Input
                    type="amount"
                    title="Base de cálculo do IRPJ"
                    value={values?.bcIR}
                    onChange={(value) => {
                      handleChangeFieldValue({
                        newItem: { bcIR: value },
                        field: "bcIR",
                      });
                    }}
                    error={errors[`[${values?.id}].bcIR`]}
                  />
                </Grid>
                <Grid size={{ xs: 4, lg: 3 }}>
                  <Input
                    type="amount"
                    title="Valor da Retenção do IRPJ"
                    value={values?.amountIR}
                    onChange={(value) => {
                      handleChangeFieldValue({
                        newItem: { amountIR: value },
                        field: "amountIR",
                      });
                    }}
                    percentageValue={amountIRPJpercentage}
                    disabled={inputDisabled === "R4020AB"}
                  />
                </Grid>
                <Grid size={{ xs: 4, lg: 3 }}>
                  <Input
                    type="amount"
                    title="Valor da Retenção do CSLL"
                    value={values?.amountCSLL}
                    percentageValue={amountCSLLpercentage}
                    onChange={(value) => {
                      handleChangeFieldValue({
                        newItem: { amountCSLL: value },
                        field: "amountCSLL",
                      });
                    }}
                    disabled={inputDisabled === "R4020AB"}
                  />
                </Grid>
                <Grid size={{ xs: 4, lg: 3 }}>
                  <Input
                    type="amount"
                    title="Valor da Retenção do PIS/Pasep"
                    value={values?.amountPIS}
                    percentageValue={amountPISpercentage}
                    onChange={(value) => {
                      handleChangeFieldValue({
                        newItem: { amountPIS: value },
                        field: "amountPIS",
                      });
                    }}
                    disabled={inputDisabled === "R4020AB"}
                  />
                </Grid>
                <Grid size={{ xs: 4, lg: 3 }}>
                  <Input
                    type="amount"
                    title="Valor da Retenção do COFINS"
                    value={values?.amountCOFINS}
                    percentageValue={amountCONFINSpercentage}
                    onChange={(value) => {
                      handleChangeFieldValue({
                        newItem: { amountCOFINS: value },
                        field: "amountCOFINS",
                      });
                    }}
                    disabled={inputDisabled === "R4020AB"}
                  />
                </Grid>
                <Grid size={{ xs: 4, lg: 3 }}>
                  <Input
                    type="amount"
                    title="Valor da Retenção não efetuada"
                    value={values?.amountNotRetained}
                    onChange={(value) => {
                      handleChangeFieldValue({
                        newItem: { amountNotRetained: value },
                        field: "amountNotRetained",
                      });
                    }}
                    disabled={inputDisabled === "R4020AB"}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default IRPJ;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    paddingBlock: 3,
  },
};
