import React from "react";
import { Box, Typography } from "@mui/material";

export const ContentSmallCard = ({
  title,
  imageSrc,
  publishDate,
  onClick,
  defaultImage,
  type = "default",
  notShowImages = false,
  autor,
}) => {
  const year = new Date(publishDate).getUTCFullYear();
  const author = autor && "por " + autor + ".";
  return (
    <Box onClick={onClick} type={type} sx={styles.container}>
      {!notShowImages && (
        <Box
          component="img"
          sx={styles.img}
          src={imageSrc || defaultImage}
          onError={(event) => {
            event.target.src = defaultImage || "";
            event.onerror = null;
          }}
          alt="small-card-image"
        />
      )}
      <Typography
        sx={{
          ...styles.text,
          lineHeight: "24.38px",
          letterSpacing: "-0.03em",
          fontSize: "1rem",
          fontWeight: 600,
        }}
      >
        {title}
      </Typography>
      {publishDate && (
        <Typography
          sx={{
            ...styles.text,
            lineHeight: "19.5px",
            letterSpacing: "-0.03em",
            fontSize: "0.9rem",
            fontWeight: 300,
            fontStyle: "italic",
          }}
        >
          Publicado em {year} {author}
        </Typography>
      )}
    </Box>
  );
};

const styles = {
  container: {
    background: "#E1E1E1",
    p: 2,
    borderRadius: "6px",
    marginTop: 2,
    marginBottom: 4,
    display: "flex",
    flexDirection: "column",
    gap: 1,
    cursor: "pointer",
    transition: "transform 0.3s ease-out, background-color 0.3s ease-out",
    "&:hover": {
      transform: "scale(1.01)",
      backgroundColor: "#E9E9E9",
    },
  },
  img: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    objectFit: "cover",
  },
  text: {
    fontFamily: "Montserrat",
    textAlign: "left",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    whiteSpace: "normal",
  },
};
