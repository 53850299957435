import React from "react";
import { makeCnaesUseCases } from "~/main/factories/use-cases/cnaes";
import { makeServiceUseCases } from "~/main/factories/use-cases/service";
import Service from "~/presentation/views/Admin/Service/Service";

export const makeServiceList = (params) => {
  return (
    <Service
      {...params}
      serviceUseCases={makeServiceUseCases()}
      cnaesUseCases={makeCnaesUseCases()}
    />
  );
};
