export default [
    {
        value: 0,
        label: 'INSS'
    },
    {
        value: 1,
        label: 'IRRF'
    },
    {
        value: 2,
        label: 'CSRF'
    },
    {
        value: 3,
        label: 'ISS'
    },
]