import { floatToReais } from "~/presentation/utils/reais-parser";
import { TransportTypes } from ".";
import { getBaseCalculus, getDependentsValue } from "./calculator";

export const reportMapper = (values, result, simulatorValue, retentionAliquot) => {
  const items = [];

  items.push({
    descriptionItem: "O rendimento é decorrente de serviço de transporte?",
    value: values.isTransportService === "true" ? "Sim" : "Não",
  });

  if (values.isTransportService === "true") {
    items.push({
      descriptionItem: "O transporte é de:",
      value: values.transportType === TransportTypes.cargo ? "Carga" : "Passageiros",
    });
  }

  items.push({
    descriptionItem: "Há dependentes?",
    value: values.hasDependents === "true" ? "Sim" : "Não",
  });

  if (values.hasDependents === "true") {
    items.push({
      descriptionItem: "Há quantos dependentes?",
      value: values.numberOfDependents.value,
    });

    items.push({
      descriptionItem: "Valor da dedução dos dependentes:",
      value: floatToReais(getDependentsValue(values)),
    });
  }

  items.push({
    descriptionItem: "Há pagamento de pensão alimentícia?",
    value: values.hasFoodPension === "true" ? "Sim" : "Não",
  });

  if (values.hasFoodPension === "true") {
    items.push({
      descriptionItem: "Valor da pensão alimentícia:",
      value: floatToReais(values.foodPensionValue),
    });
  }

  items.push(
    {
      descriptionItem: "Base de cálculo da retenção do IR",
      value: floatToReais(getBaseCalculus(values, simulatorValue)),
    },
    {
      descriptionItem: "Alíquota de retenção do IR",
      value: retentionAliquot?.label,
    },
    {
      descriptionItem: "Valor da retenção do IR:",
      value: floatToReais(result),
    }
  );

  let report = {
    title: "IRRF",
    tax: 1,
    retentionValue: result,
    items,
  };

  return report;
};
